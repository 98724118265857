import { ModalCloseButton, ModalContent, ModalOverlay, Modal as ModalWrapper, useDisclosure } from "@chakra-ui/react"
import { CustomModalPropTypes, UseModalReturnTypes } from "../types/useModal"

/**  Example usage of useModal hook inside a component
    const {Modal: PdpModal, onOpen: onOpenPdpModal} = useModal();
    <Button onClick={onOpen}>Open Modal</Button>
    <PdpModal bodyChildren={<Component />} **/

export default function useModal(): UseModalReturnTypes {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const Modal = ({ children, modalContentStyleProps, size, customOnClose, ...rest }: CustomModalPropTypes) => {
    return (
      <ModalWrapper
        size={size}
        closeOnOverlayClick
        isOpen={isOpen}
        onClose={customOnClose ?? onClose}
        initialFocusRef={null}
        isCentered={true}
        preserveScrollBarGap={false}
        {...rest}
      >
        <ModalOverlay bg="blackAlpha.500">
          <ModalContent borderRadius="0" {...modalContentStyleProps}>
            <ModalCloseButton
              w={{ base: "11", sm: "10" }}
              h={{ base: "11", sm: "10" }}
              top="4"
              right="4"
              zIndex="docked"
            />
            {children}
          </ModalContent>
        </ModalOverlay>
      </ModalWrapper>
    )
  }
  return {
    Modal,
    onOpen,
    isOpen,
    onClose,
  }
}
