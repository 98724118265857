import { IconButton } from "@chakra-ui/react"
import { CClose, CSearch } from "../../../../shared/Icons"

type Props = {
  isOpen: boolean
  onToggle: () => void
}

const MobileSearchField = ({ isOpen, onToggle }: Props) => {
  return (
    <IconButton
      aria-label={`${isOpen ? "Hide" : "Show"} search field`}
      color="black"
      variant="secondary"
      icon={isOpen ? <CClose /> : <CSearch />}
      bg="transparent"
      onClick={onToggle}
      width={{ base: "11", md: "10" }}
    />
  )
}

export default MobileSearchField
