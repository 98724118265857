/* tslint:disable */
/* eslint-disable */
/**
 * Assets
 * The Assets API provides endpoints for retrieving document and video data for available articles and products. You can also refine the results according to your needs using filters.
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
