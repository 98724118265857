import { Box, Button, Flex, IconButton, Slide, Text, useOutsideClick } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useMemo, useRef, useState } from "react"
import { layout, localesWithoutSeries, mobileMenuSectionNames, pages } from "../../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import useScrollBlock from "../../../../../common/hooks/useScrollBlock"
import { MobileMenuSectionNamesType } from "../../../../../common/types/global"
import { scrollToTop } from "../../../../../common/utils/common-utils"
import { getAvailableLanguages } from "../../../../../common/utils/locale-utils"
import BackButton from "../../../../shared/BackButton"
import { CChevronRight, CClose, CMenu } from "../../../../shared/Icons"
import NavigationButtonLink from "../../../../shared/NavigationButtonLink"
import NavigationMenuButton from "../../../../shared/NavigationMenuButton"
import MobileSystemMenu from "../../../systems/SystemMenu/MobileSystemMenu/MobileSystemMenu"

import dynamic from "next/dynamic"
import { Category } from "../../../../../api-clients/product-api-v1"
import useModal from "../../../../../common/hooks/useModal"
import useUserLists from "../../../../../common/hooks/useUserLists"
import InputModal from "../../../my-account/MyList/MyListDetails/InputModal/InputModal"
import CompanyWebsiteLink from "../CompanyWebsiteLink"
import MobileLanguagesMenu from "./MobileLanguagesMenu"
import MobileMyListsMenu from "./MobileMyListsMenu"

const MobileAccountMenu = dynamic(() => import("./MobileAccountMenu"), { ssr: false })

type Props = {
  systemMenuData: Category
  systemCategoriesLinks: React.ReactNode
}

const MobileNavigation = ({ systemMenuData, systemCategoriesLinks }: Props) => {
  const { t } = useBrandedTranslation()
  const { locale, locales } = useRouter()
  /* Custom hook to get the user lists data */
  const { createList } = useUserLists()

  const {
    Modal: CreateList,
    isOpen: isCreateListModalOpen,
    onOpen: onOpenCreateListModal,
    onClose: onCloseCreateListModal,
  } = useModal()

  const handleCreateCustomList = (name: string) => {
    createList({ name, isDefault: true })
  }

  /* Custom hook to block body scroll */
  const [blockScroll, allowScroll] = useScrollBlock()

  const [isOpen, setIsOpen] = useState(false)
  const [activeMenu, setActiveMenu] = useState<MobileMenuSectionNamesType>(mobileMenuSectionNames.MAIN_MENU_SECTION)
  const mobileMenuRef = useRef(null)
  const isOutsideClick = useRef(false)

  const hasSeries = !localesWithoutSeries.includes(locale as string)

  /* Chakra UI hook to handle clicks outside of the mobile menu */
  useOutsideClick({
    ref: mobileMenuRef,
    handler: () => handleCloseMenu(),
  })

  /* Get all available languages for the current domain */
  const availableLanguages = useMemo(
    () => getAvailableLanguages(locale as string, locales as string[]),
    [locale, locales]
  )

  const selectedLanguage = availableLanguages.find((lng) => lng.locale === locale)

  /* Scheduler for a reset of the menu internal state after it is closed */
  const resetMobileMenu = () => {
    setTimeout(() => {
      isOutsideClick.current = false
      setActiveMenu(mobileMenuSectionNames.MAIN_MENU_SECTION)
    }, 200)
  }

  /* Pass this handler to the mobile menu sections to close and reset the menu on button click.
    This function is called only by the handler of the useOutsideClick hook */
  const handleCloseMenu = () => {
    if (isOpen) {
      /* Indicate that there is a click outside the menu */
      isOutsideClick.current = true
      allowScroll()
      /* Close the menu */
      setIsOpen(false)
      /* Reset the menu after it is closed */
      resetMobileMenu()
    }
  }

  /* Handler for the hamburger icon used to open the menu */
  const handleOpenMenu = () => {
    /* Open the menu only of it hasn't been blurred right before that */
    if (!isOutsideClick?.current) {
      /* If window is not to top, scroll all the way up before opening the menu */
      scrollToTop()
      /* Block the body scroll while the menu is open */
      blockScroll()
      /* Open the menu */
      setIsOpen(true)
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        aria-label={`${isOpen ? "Close" : "Open"} mobile navigation menu`}
        color="black"
        icon={isOpen ? <CClose /> : <CMenu />}
        variant="secondary"
        width={{ base: "11", md: "10" }}
      />

      <Slide
        direction="right"
        in={isOpen}
        unmountOnExit={false}
        style={{ zIndex: 1500, top: layout.MOBILE_HEADER_HEIGHT, width: "auto" }}
      >
        <Flex
          as="nav"
          h="100%"
          flexDirection="column"
          bg="white"
          boxShadow="lgInsetTopOnly"
          width={{ base: "100vw", xs: "80" }}
          overflowY="auto"
          color="black"
          ref={mobileMenuRef}
        >
          <Flex as="section" flexDirection="column" justifyContent="flex-start" px="4" py="6" w="100%">
            {activeMenu !== mobileMenuSectionNames.MAIN_MENU_SECTION && (
              <BackButton
                onClick={() => setActiveMenu(mobileMenuSectionNames.MAIN_MENU_SECTION)}
                text={t("acc-start")}
              />
            )}

            {activeMenu === mobileMenuSectionNames.MAIN_MENU_SECTION && (
              <Flex flexDirection="column">
                <NavigationMenuButton
                  label={t("nav-assortment")}
                  onClick={() => setActiveMenu(mobileMenuSectionNames.SYSTEM_MENU)}
                  rightIcon={<CChevronRight />}
                />
                {/* Inject all system categories links for SEO indexing purposes */}
                {systemCategoriesLinks}

                {hasSeries && (
                  <NavigationButtonLink href={`/${pages.SERIES}`} label={t("nav-series")} onClick={handleCloseMenu} />
                )}

                <NavigationButtonLink
                  href={`/${pages.NOVELTIES}`}
                  label={t("nav-newReleases")}
                  onClick={handleCloseMenu}
                />
              </Flex>
            )}

            <MobileSystemMenu
              isActive={activeMenu === mobileMenuSectionNames.SYSTEM_MENU}
              handleCloseMenu={handleCloseMenu}
              systemMenuData={systemMenuData}
            />
            <MobileMyListsMenu
              isActive={activeMenu === mobileMenuSectionNames.MY_LISTS_MENU}
              handleCloseMenu={handleCloseMenu}
              onOpenCreateListModal={onOpenCreateListModal}
            />
            <MobileLanguagesMenu
              isActive={activeMenu === mobileMenuSectionNames.LANGUAGE_MENU}
              availableLanguages={availableLanguages}
              selectedLanguage={selectedLanguage}
              handleCloseMenu={handleCloseMenu}
            />
          </Flex>

          <Flex as="section" px="4" py="6" flexDirection="column" bgColor="gray.50" w="100%" h="100%">
            <MobileAccountMenu
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              handleCloseMenu={handleCloseMenu}
            />
            <CompanyWebsiteLink my="6" onClick={handleCloseMenu} />
            {/* Languages selector shown only when more than one language for the locale */}
            {availableLanguages?.length > 1 && activeMenu !== mobileMenuSectionNames.LANGUAGE_MENU && (
              <Button
                justifyContent="space-between"
                variant="mobileMenu"
                rightIcon={<CChevronRight />}
                px="2"
                onClick={() => setActiveMenu(mobileMenuSectionNames.LANGUAGE_MENU)}
              >
                <Flex lineHeight="base">
                  <Text casing="capitalize" mr="1">
                    {selectedLanguage.displayLanguage}
                  </Text>
                  <Text>{"-"}</Text>
                  <Text casing="uppercase" ml="1">
                    {selectedLanguage.languageCode}
                  </Text>
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex>
      </Slide>

      {/* Background overlay */}
      {isOpen && (
        <Box
          position="fixed"
          zIndex="overlay"
          top={layout.MOBILE_HEADER_HEIGHT}
          right="0"
          bottom="0"
          left="0"
          bgColor="blackAlpha.500"
        ></Box>
      )}

      <InputModal
        isOpen={isCreateListModalOpen}
        Modal={CreateList}
        title={t("acc-myLists-add")}
        initialValue={t("wishlist-default-name")}
        onClose={onCloseCreateListModal}
        handleConfirm={handleCreateCustomList}
      />
    </>
  )
}

export default MobileNavigation
