/*  Domain Configuration for OCNext
    Parts of this config are used in:
    - next-i18next.config
    - _middleware
    - next.config
*/
module.exports = {
  // Default-brand for all unbranded URLs to rewrite
  // e.g. ocnext.com -> /_sites/[defaultBrand]/
  defaultBrand: "geberit",
  // locale placeholder string for redirecting:
  // e.g. ocnext.com -> ocnext.com/en-EU
  localePlaceholder: "placeholder-",
  // for labels: brands are mapped to namespaces
  // used in next-i18next.config
  namespaces: ["main", "ido", "porsgrund", "ifo", "kolo", "twyford"],
  // These domains will be routed to default-brand & default language
  fallbackDomains: [
    "localhost:3000",
    "webapp-2.nextjs.dev.iv2.catalog.geberit.com",
    "webapp.nextjs.dev.iv2.catalog.geberit.com",
    "webapp.nextjs.stg.iv2.catalog.geberit.com",
    "webapp.nextjs.prd.iv2.catalog.geberit.com",
  ],
  // Domain urls are setup in order PRD, DEV, STG, Temp PRD (to be removed after Go Live)
  brandDomains: [
    {
      brand: "geberit",
      domains: ["catalog.geberit-global.com"],
      defaultLocale: "en-NT",
      locales: ["en-NT", "en-GU", "en-SA", "en-EU", "fr-FA"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.cz", "geberit.cz.dev.catalog.geberit.com", "geberit.cz.stg.catalog.geberit.com"],
      defaultLocale: "cs-CZ",
      locales: ["cs-CZ"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.dk", "geberit.dk.dev.catalog.geberit.com", "geberit.dk.stg.catalog.geberit.com"],
      defaultLocale: "da-DK",
      locales: ["da-DK"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.at", "geberit.at.dev.catalog.geberit.com", "geberit.at.stg.catalog.geberit.com"],
      defaultLocale: "de-AT",
      locales: ["de-AT"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.ch", "geberit.ch.dev.catalog.geberit.com", "geberit.ch.stg.catalog.geberit.com"],
      defaultLocale: "de-CH",
      locales: ["de-CH", "fr-CH", "it-CH"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.de", "geberit.de.dev.catalog.geberit.com", "geberit.de.stg.catalog.geberit.com"],
      defaultLocale: "de-DE",
      locales: ["de-DE"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.com.au", "geberit.au.dev.catalog.geberit.com", "geberit.au.stg.catalog.geberit.com"],
      defaultLocale: "en-AU",
      locales: ["en-AU"],
    },
    {
      brand: "geberit",
      domains: [
        "catalog.international.geberit.com",
        "geberit.eu.dev.catalog.geberit.com",
        "geberit.eu.stg.catalog.geberit.com",
      ],
      defaultLocale: "en-EU",
      locales: ["en-EU"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.co.uk", "geberit.gb.dev.catalog.geberit.com", "geberit.gb.stg.catalog.geberit.com"],
      defaultLocale: "en-GB",
      locales: ["en-GB"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.gu", "geberit.gu.dev.catalog.geberit.com", "geberit.gu.stg.catalog.geberit.com"],
      defaultLocale: "en-GU",
      locales: ["en-GU"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.in", "geberit.in.dev.catalog.geberit.com", "geberit.in.stg.catalog.geberit.com"],
      defaultLocale: "en-IN",
      locales: ["en-IN"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.nt", "geberit.nt.dev.catalog.geberit.com", "geberit.nt.stg.catalog.geberit.com"],
      defaultLocale: "en-NT",
      locales: ["en-NT"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.sa", "geberit.sa.dev.catalog.geberit.com", "geberit.sa.stg.catalog.geberit.com"],
      defaultLocale: "en-SA",
      locales: ["en-SA"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.com.sg", "geberit.sg.dev.catalog.geberit.com", "geberit.sg.stg.catalog.geberit.com"],
      defaultLocale: "en-SG",
      locales: ["en-SG"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.us", "geberit.us.dev.catalog.geberit.com", "geberit.us.stg.catalog.geberit.com"],
      defaultLocale: "en-US",
      locales: ["en-US"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.co.za", "geberit.za.dev.catalog.geberit.com", "geberit.za.stg.catalog.geberit.com"],
      defaultLocale: "en-ZA",
      locales: ["en-ZA"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.es", "geberit.es.dev.catalog.geberit.com", "geberit.es.stg.catalog.geberit.com"],
      defaultLocale: "es-ES",
      locales: ["es-ES"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.ee", "geberit.ee.dev.catalog.geberit.com", "geberit.ee.stg.catalog.geberit.com"],
      defaultLocale: "et-EE",
      locales: ["et-EE"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.fi", "geberit.fi.dev.catalog.geberit.com", "geberit.fi.stg.catalog.geberit.com"],
      defaultLocale: "fi-FI",
      locales: ["fi-FI"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.be", "geberit.be.dev.catalog.geberit.com", "geberit.be.stg.catalog.geberit.com"],
      defaultLocale: "nl-BE",
      locales: ["nl-BE", "fr-BE"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.fa", "geberit.fa.dev.catalog.geberit.com", "geberit.fa.stg.catalog.geberit.com"],
      defaultLocale: "fr-FA",
      locales: ["fr-FA"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.fr", "geberit.fr.dev.catalog.geberit.com", "geberit.fr.stg.catalog.geberit.com"],
      defaultLocale: "fr-FR",
      locales: ["fr-FR"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.lu", "geberit.lu.dev.catalog.geberit.com", "geberit.lu.stg.catalog.geberit.com"],
      defaultLocale: "fr-LU",
      locales: ["fr-LU"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.hr", "geberit.hr.dev.catalog.geberit.com", "geberit.hr.stg.catalog.geberit.com"],
      defaultLocale: "hr-HR",
      locales: ["hr-HR"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.hu", "geberit.hu.dev.catalog.geberit.com", "geberit.hu.stg.catalog.geberit.com"],
      defaultLocale: "hu-HU",
      locales: ["hu-HU"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.it", "geberit.it.dev.catalog.geberit.com", "geberit.it.stg.catalog.geberit.com"],
      defaultLocale: "it-IT",
      locales: ["it-IT"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.lt", "geberit.lt.dev.catalog.geberit.com", "geberit.lt.stg.catalog.geberit.com"],
      defaultLocale: "lt-LT",
      locales: ["lt-LT"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.lv", "geberit.lv.dev.catalog.geberit.com", "geberit.lv.stg.catalog.geberit.com"],
      defaultLocale: "lv-LV",
      locales: ["lv-LV"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.nl", "geberit.nl.dev.catalog.geberit.com", "geberit.nl.stg.catalog.geberit.com"],
      defaultLocale: "nl-NL",
      locales: ["nl-NL"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.no", "geberit.no.dev.catalog.geberit.com", "geberit.no.stg.catalog.geberit.com"],
      defaultLocale: "no-NO",
      locales: ["no-NO"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.pl", "geberit.pl.dev.catalog.geberit.com", "geberit.pl.stg.catalog.geberit.com"],
      defaultLocale: "pl-PL",
      locales: ["pl-PL"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.pt", "geberit.pt.dev.catalog.geberit.com", "geberit.pt.stg.catalog.geberit.com"],
      defaultLocale: "pt-PT",
      locales: ["pt-PT"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.ro", "geberit.ro.dev.catalog.geberit.com", "geberit.ro.stg.catalog.geberit.com"],
      defaultLocale: "ro-RO",
      locales: ["ro-RO"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.by", "geberit.by.dev.catalog.geberit.com", "geberit.by.stg.catalog.geberit.com"],
      defaultLocale: "ru-BY",
      locales: ["ru-BY"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.ru", "geberit.ru.dev.catalog.geberit.com", "geberit.ru.stg.catalog.geberit.com"],
      defaultLocale: "ru-RU",
      locales: ["ru-RU"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.sk", "geberit.sk.dev.catalog.geberit.com", "geberit.sk.stg.catalog.geberit.com"],
      defaultLocale: "sk-SK",
      locales: ["sk-SK"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.si", "geberit.si.dev.catalog.geberit.com", "geberit.si.stg.catalog.geberit.com"],
      defaultLocale: "sl-SI",
      locales: ["sl-SI"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.rs", "geberit.rs.dev.catalog.geberit.com", "geberit.rs.stg.catalog.geberit.com"],
      defaultLocale: "sr-RS",
      locales: ["sr-RS"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.se", "geberit.se.dev.catalog.geberit.com", "geberit.se.stg.catalog.geberit.com"],
      defaultLocale: "sv-SE",
      locales: ["sv-SE"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.com.tr", "geberit.tr.dev.catalog.geberit.com", "geberit.tr.stg.catalog.geberit.com"],
      defaultLocale: "tr-TR",
      locales: ["tr-TR"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.ua", "geberit.ua.dev.catalog.geberit.com", "geberit.ua.stg.catalog.geberit.com"],
      defaultLocale: "uk-UA",
      locales: ["uk-UA"],
    },
    {
      brand: "geberit",
      domains: ["catalog.kz.geberit.com", "geberit.kz.dev.catalog.geberit.com", "geberit.kz.stg.catalog.geberit.com"],
      defaultLocale: "ru-KZ",
      locales: ["ru-KZ"],
    },
    {
      brand: "geberit",
      domains: ["catalog.geberit.com.cn", "geberit.cn.dev.catalog.geberit.com", "geberit.cn.stg.catalog.geberit.com"],
      defaultLocale: "zh-CN",
      locales: ["zh-CN"],
    },
    {
      brand: "ido",
      domains: ["catalog.ido.fi", "ido.fi.dev.catalog.geberit.com", "ido.fi.stg.catalog.geberit.com"],
      defaultLocale: "fi-FI",
      locales: ["fi-FI"],
    },
    {
      brand: "ifo",
      domains: ["catalog.ifo.dk", "ifo.dk.dev.catalog.geberit.com", "ifo.dk.stg.catalog.geberit.com"],
      defaultLocale: "da-DK",
      locales: ["da-DK"],
    },
    {
      brand: "ifo",
      domains: ["catalog.ifo.ee", "ifo.ee.dev.catalog.geberit.com", "ifo.ee.stg.catalog.geberit.com"],
      defaultLocale: "et-EE",
      locales: ["et-EE"],
    },
    {
      brand: "ifo",
      domains: ["catalog.ifo.lt", "ifo.lt.dev.catalog.geberit.com", "ifo.lt.stg.catalog.geberit.com"],
      defaultLocale: "lt-LT",
      locales: ["lt-LT"],
    },
    {
      brand: "ifo",
      domains: ["catalog.ifo.lv", "ifo.lv.dev.catalog.geberit.com", "ifo.lv.stg.catalog.geberit.com"],
      defaultLocale: "lv-LV",
      locales: ["lv-LV"],
    },
    {
      brand: "ifo",
      domains: ["catalog.ifo.se", "ifo.se.dev.catalog.geberit.com", "ifo.se.stg.catalog.geberit.com"],
      defaultLocale: "sv-SE",
      locales: ["sv-SE"],
    },
    {
      brand: "porsgrund",
      domains: [
        "catalog.porsgrundbad.no",
        "porsgrund.no.dev.catalog.geberit.com",
        "porsgrund.no.stg.catalog.geberit.com",
      ],
      defaultLocale: "no-NO",
      locales: ["no-NO"],
    },
    {
      brand: "kolo",
      domains: ["catalog.kolo.com.pl", "kolo.pl.dev.catalog.geberit.com", "kolo.pl.stg.catalog.geberit.com"],
      defaultLocale: "pl-PL",
      locales: ["pl-PL"],
    },
    {
      brand: "kolo",
      domains: ["catalog.kolo.ua", "kolo.ua.dev.catalog.geberit.com", "kolo.ua.stg.catalog.geberit.com"],
      defaultLocale: "uk-UA",
      locales: ["uk-UA"],
    },
    {
      brand: "twyford",
      domains: [
        "catalog.twyfordbathrooms.com",
        "twyford.gb.dev.catalog.geberit.com",
        "twyford.gb.stg.catalog.geberit.com",
      ],
      defaultLocale: "en-GB",
      locales: ["en-GB"],
    },
  ],
}
