import { Box, Container, Flex } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import { ChakraNextLinkButton } from "../../../../shared/ChakraNextLinkButton"
import { CompanyLogo } from "../CompanyLogo"
import LanguagesMenu from "../LanguagesMenu"
import ArchivedMobileNavigation from "../MobileNavigation/ArchivedMobileNavigation"

const ArchivedHeader = () => {
  const { t } = useBrandedTranslation()

  return (
    <Flex as="header" flexDirection="column" boxShadow="lg">
      <Box display={{ base: "none", md: "block" }} bg="gray.50" width="100%">
        <Container variant="main">
          <LanguagesMenu redirectToHomepage={false} />
        </Container>
      </Box>

      <Container
        variant="main"
        height={{ base: "15", xs: "17", md: "25" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <CompanyLogo />

        <Flex gap="4" display={{ base: "none", sm: "flex" }}>
          <Box display={{ base: "block", md: "none" }}>
            <LanguagesMenu redirectToHomepage={false} useDefaultStyles={false} />
          </Box>
          <ChakraNextLinkButton
            color="white"
            target="_blank"
            href="/"
            variant="primary"
            label={t("archiv-header-toProductcatalog")}
          />
        </Flex>
        <Box display={{ base: "block", sm: "none" }}>
          <ArchivedMobileNavigation />
        </Box>
      </Container>
    </Flex>
  )
}

export default ArchivedHeader
