import { Box, Button, Flex, IconButton, Slide, Text, useBreakpointValue, useOutsideClick } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useMemo, useRef, useState } from "react"
import { layout } from "../../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import useScrollBlock from "../../../../../common/hooks/useScrollBlock"
import { scrollToTop } from "../../../../../common/utils/common-utils"
import { getAvailableLanguages } from "../../../../../common/utils/locale-utils"
import BackButton from "../../../../shared/BackButton"
import { ChakraNextLinkButton } from "../../../../shared/ChakraNextLinkButton"
import { CChevronRight, CClose, CMenu } from "../../../../shared/Icons"
import MobileLanguagesMenu from "./MobileLanguagesMenu"

const ArchivedMobileNavigation = () => {
  const { t } = useBrandedTranslation()
  const { locale, locales } = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [showLanguageMenu, setShowLanguageMenu] = useState(false)

  const mobileMenuRef = useRef(null)
  const isOutsideClick = useRef(false)

  /* We have different sizes for the mobile header, that's why we need to pass different size on some places */
  const menuHeight = useBreakpointValue({ base: layout.MOBILE_HEADER_HEIGHT, xs: "68px" })

  /* Custom hook to block body scroll */
  const [blockScroll, allowScroll] = useScrollBlock()

  /* Chakra UI hook to handle clicks outside of the mobile menu */
  useOutsideClick({
    ref: mobileMenuRef,
    handler: () => handleCloseMenu(),
  })

  /* Get all available languages for the current domain */
  const availableLanguages = useMemo(
    () => getAvailableLanguages(locale as string, locales as string[]),
    [locale, locales]
  )

  const selectedLanguage = availableLanguages.find((lng) => lng.locale === locale)

  /* Scheduler for a reset of the menu internal state after it is closed */
  const resetMobileMenu = () => {
    setTimeout(() => {
      isOutsideClick.current = false
      setShowLanguageMenu(false)
    }, 200)
  }

  /* Pass this handler to the mobile menu sections to close and reset the menu on button click.
      This function is called only by the handler of the useOutsideClick hook */
  const handleCloseMenu = () => {
    if (isOpen) {
      /* Indicate that there is a click outside the menu */
      isOutsideClick.current = true
      allowScroll()
      /* Close the menu */
      setIsOpen(false)
      /* Reset the menu after it is closed */
      resetMobileMenu()
    }
  }

  /* Handler for the hamburger icon used to open the menu */
  const handleOpenMenu = () => {
    /* Open the menu only of it hasn't been blurred right before that */
    if (!isOutsideClick?.current) {
      /* If window is not to top, scroll all the way up before opening the menu */
      scrollToTop()
      /* Block the body scroll while the menu is open */
      blockScroll()
      /* Open the menu */
      setIsOpen(true)
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        aria-label={`${isOpen ? "Close" : "Open"} mobile navigation menu`}
        color="black"
        icon={isOpen ? <CClose /> : <CMenu />}
        variant="secondary"
        width={{ base: "11", md: "10" }}
      />

      <Slide
        direction="right"
        in={isOpen}
        unmountOnExit={false}
        style={{ zIndex: 1500, top: menuHeight, width: "auto" }}
      >
        <Flex
          as="nav"
          h="100%"
          flexDirection="column"
          bg="white"
          boxShadow="lgInsetTopOnly"
          width={{ base: "100vw", xs: "80" }}
          overflowY="auto"
          color="black"
          ref={mobileMenuRef}
        >
          <Flex as="section" px="4" py="6" flexDirection="column" bgColor="gray.50" w="100%" h="100%">
            <Box marginBottom="4">
              <ChakraNextLinkButton
                color="white"
                width="100%"
                target="_blank"
                href="/"
                variant="primary"
                label={t("archiv-header-toProductcatalog")}
              />
            </Box>

            <Box paddingTop="4" borderTop="1px solid black" borderColor="gray.300">
              {/* Languages selector shown only when more than one language for the locale */}
              {availableLanguages?.length > 1 && !showLanguageMenu && (
                <Button
                  justifyContent="space-between"
                  variant="mobileMenu"
                  rightIcon={<CChevronRight />}
                  px="2"
                  onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                >
                  <Flex lineHeight="base">
                    <Text casing="capitalize" mr="1">
                      {selectedLanguage.displayLanguage}
                    </Text>
                    <Text>{"-"}</Text>
                    <Text casing="uppercase" ml="1">
                      {selectedLanguage.languageCode}
                    </Text>
                  </Flex>
                </Button>
              )}

              {showLanguageMenu && <BackButton text={t("mcat-back")} onClick={() => setShowLanguageMenu(false)} />}
            </Box>

            <MobileLanguagesMenu
              isActive={showLanguageMenu}
              availableLanguages={availableLanguages}
              selectedLanguage={selectedLanguage}
              handleCloseMenu={handleCloseMenu}
              redirectToHomepage={false}
            />
          </Flex>
        </Flex>
      </Slide>

      {/* Background overlay */}
      {isOpen && (
        <Box
          position="fixed"
          zIndex="overlay"
          top={menuHeight}
          right="0"
          bottom="0"
          left="0"
          bgColor="blackAlpha.500"
        ></Box>
      )}
    </>
  )
}

export default ArchivedMobileNavigation
