import { Box, ButtonGroup, Collapse, Flex, IconButton, Link, useDisclosure } from "@chakra-ui/react"
import { Category } from "../../../../api-clients/product-api-v1"
import { layout } from "../../../../common/constants/constants"
import CustomLink from "../../../shared/CustomLink"
import { CHelp } from "../../../shared/Icons"
import SuggestionsContainer from "../../suggestions/containers/SuggestionsContainer"
import { CompanyLogo } from "./CompanyLogo"
import MobileNavigation from "./MobileNavigation/MobileNavigation"
import MobileSearchField from "./MobileSearchField/MobileSearchField"

type Props = {
  systemMenuData: Category
  systemCategoriesLinks: React.ReactNode
}

export const HeaderMobile = ({ systemMenuData, systemCategoriesLinks }: Props) => {
  const { isOpen: isSearchFieldOpen, onToggle: onToggleSearchField } = useDisclosure()

  return (
    <>
      {/* This is the default rendered header for server-side rendering.
    Make it look like the desktop one when it is initially rendered on desktop size.
    Make it hidden instead of not displayed, so robots can still read the header content. */}
      <Flex
        flexDirection="column"
        boxShadow="lg"
        as="header"
        visibility={{ base: "visible", md: "hidden" }}
        h={{ base: "auto", md: "35" }}
      >
        <Flex
          position="relative"
          align="center"
          px={{ base: "4", sm: "6" }}
          justify="space-between"
          h={layout.MOBILE_HEADER_HEIGHT}
          /* Animated bottom border when the search field is open */
          _before={{
            content: '""',
            position: "absolute",
            display: "block",
            bgColor: "gray.300",
            width: "100%",
            height: "px",
            bottom: "0",
            left: "0",
            transform: `scaleX(${isSearchFieldOpen ? 1 : 0})`,
            transition: `transform ${isSearchFieldOpen ? "0.35s ease-in" : "0.2s ease-out"}`,
          }}
        >
          <CustomLink href="/">
            <Link _hover={{ textDecoration: "none" }}>
              <CompanyLogo />
            </Link>
          </CustomLink>
          <Flex mr="-2">
            <ButtonGroup spacing="0" color="gray.900">
              <IconButton
                className="tutorial-button" // GTM Event: Tutorial button click
                aria-label="Open help menu"
                icon={<CHelp className="tutorial-button" />}
                bg="transparent"
                color="black"
                variant="secondary"
                display="none" // TODO (vva) change when user onboarding is ready
              />
              <MobileSearchField isOpen={isSearchFieldOpen} onToggle={onToggleSearchField} />
              <MobileNavigation systemMenuData={systemMenuData} systemCategoriesLinks={systemCategoriesLinks} />
            </ButtonGroup>
          </Flex>
        </Flex>
        <Collapse in={isSearchFieldOpen} style={{ overflow: "visible" }} unmountOnExit>
          <Box px={{ base: "4", sm: "6" }} pt="2" pb="4">
            <SuggestionsContainer onToggleSearchField={onToggleSearchField} />
          </Box>
        </Collapse>
      </Flex>
    </>
  )
}
