/* tslint:disable */
/* eslint-disable */
/**
 * Assets
 * The Assets API provides endpoints for retrieving document and video data for available articles and products. You can also refine the results according to your needs using filters.
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from "./base"

/**
 * Contains information about the documents linked to an available article.
 * @export
 * @interface ArticleDocuments
 */
export interface ArticleDocuments {
  /**
   * The article number ([SKU](/glossary)) for which to list the documents.
   * @type {string}
   * @memberof ArticleDocuments
   */
  id?: string
  /**
   * An `array` of `Document` objects. Contains information about documents.
   * @type {Array<Document>}
   * @memberof ArticleDocuments
   */
  documents?: Array<Document>
}
/**
 * Contains information about the videos linked to an available article.
 * @export
 * @interface ArticleVideos
 */
export interface ArticleVideos {
  /**
   * The article number ([SKU](/glossary)) for which to list the videos.
   * @type {string}
   * @memberof ArticleVideos
   */
  id?: string
  /**
   * An `array` of `Video` objects. Contains information about the article\'s videos.
   * @type {Array<Video>}
   * @memberof ArticleVideos
   */
  videos?: Array<Video>
}
/**
 * Contains information about a document, such as the document URL, title, and languages.
 * @export
 * @interface Document
 */
export interface Document {
  /**
   * The title of the document.
   * @type {string}
   * @memberof Document
   */
  title?: string
  /**
   * The [European Standard](https://www.cencenelec.eu/european-standardization/european-standards/) (Europäische Norm) to which the document conforms.
   * @type {string}
   * @memberof Document
   */
  enNumber?: string
  /**
   * The type of the document. You can view all available values in [this documentation](/locales).
   * @type {string}
   * @memberof Document
   */
  documentType?: DocumentDocumentTypeEnum
  /**
   * The application type for which the format of the document is optimized. Can be either `Web` or `Mobile`.
   * @type {string}
   * @memberof Document
   */
  application?: DocumentApplicationEnum
  /**
   * The CDN URL of the document.
   * @type {string}
   * @memberof Document
   */
  url?: string
  /**
   * An `array` of languages present within the document. Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example `EN` represents English.
   * @type {Array<string>}
   * @memberof Document
   */
  languages?: Array<string>
}

export const DocumentDocumentTypeEnum = {
  Tdu: "TDU",
  Tma: "TMA",
  Tle: "TLE",
  Tpa: "TPA",
  Tmh: "TMH",
  Tko: "TKO",
  Tbe: "TBE",
  Tsd: "TSD",
  Tba: "TBA",
  Tpn: "TPN",
  Tka: "TKA",
  Tsb: "TSB",
  Tdb: "TDB",
  Tas: "TAS",
  Tia: "TIA",
  Thb: "THB",
  Tin: "TIN",
  Tsh: "TSH",
  Teb: "TEB",
  Tif: "TIF",
} as const

export type DocumentDocumentTypeEnum = (typeof DocumentDocumentTypeEnum)[keyof typeof DocumentDocumentTypeEnum]
export const DocumentApplicationEnum = {
  Web: "Web",
  Mobile: "Mobile",
} as const

export type DocumentApplicationEnum = (typeof DocumentApplicationEnum)[keyof typeof DocumentApplicationEnum]

/**
 * Contains information about an error.
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   * The HTTP status code returned by the service.
   * @type {number}
   * @memberof ErrorMessage
   */
  statusCode?: number
  /**
   * A brief, human-readable description of the error.
   * @type {string}
   * @memberof ErrorMessage
   */
  message?: string
}
/**
 * Contains information about the documents linked to an available product.
 * @export
 * @interface ProductDocuments
 */
export interface ProductDocuments {
  /**
   * The product ID for which to list the documents.
   * @type {string}
   * @memberof ProductDocuments
   */
  id?: string
  /**
   * An `array` of `Document` objects. Contains information about documents.
   * @type {Array<Document>}
   * @memberof ProductDocuments
   */
  documents?: Array<Document>
}
/**
 * Contains information about the videos linked to an available product.
 * @export
 * @interface ProductVideos
 */
export interface ProductVideos {
  /**
   * The product ID for which to list the videos.
   * @type {string}
   * @memberof ProductVideos
   */
  id?: string
  /**
   * An `array` of `Video` objects. Contains information about the product\'s videos.
   * @type {Array<Video>}
   * @memberof ProductVideos
   */
  videos?: Array<Video>
}
/**
 * Contains information about a video, such as the URL and the platform it\'s hosted.
 * @export
 * @interface Video
 */
export interface Video {
  /**
   * The URL of the video.
   * @type {string}
   * @memberof Video
   */
  url?: string
  /**
   * The platform on which the video is hosted.
   * @type {string}
   * @memberof Video
   */
  platform?: string
}

/**
 * ArticleAssetsApi - axios parameter creator
 * @export
 */
export const ArticleAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a locale and a list of article numbers ([SKUs](/glossary)) to retrieve document data for the available articles. You can refine the results by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for one or more articles.
     * @param {string} locale The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} ids The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>} [documentTypes] The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
     * @param {'Web' | 'Mobile'} [application] The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
     * @param {Array<string>} [languages] The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAssets: async (
      locale: string,
      ids: Array<string>,
      documentTypes?: Array<
        | "TDU"
        | "TMA"
        | "TLE"
        | "TPA"
        | "TMH"
        | "TKO"
        | "TBE"
        | "TSD"
        | "TBA"
        | "TPN"
        | "TKA"
        | "TSB"
        | "TDB"
        | "TAS"
        | "TIA"
        | "THB"
        | "TIN"
        | "TSH"
        | "TEB"
        | "TIF"
      >,
      application?: "Web" | "Mobile",
      languages?: Array<string>,
      showGlobal?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getArticleAssets", "locale", locale)
      // verify required parameter 'ids' is not null or undefined
      assertParamExists("getArticleAssets", "ids", ids)
      const localVarPath = `/documents/articles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (ids) {
        localVarQueryParameter["ids"] = ids
      }

      if (documentTypes) {
        localVarQueryParameter["documentTypes"] = documentTypes
      }

      if (application !== undefined) {
        localVarQueryParameter["application"] = application
      }

      if (languages) {
        localVarQueryParameter["languages"] = languages
      }

      if (showGlobal != null) {
        localVarHeaderParameter["show-global"] =
          typeof showGlobal === "string" ? showGlobal : JSON.stringify(showGlobal)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and list of article numbers ([SKUs](/glossary)) to retrieve video data for the available articles.
     * @summary Get video data for one or more articles.
     * @param {string} locale The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} ids The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticlesAssets: async (
      locale: string,
      ids: Array<string>,
      showGlobal?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getArticlesAssets", "locale", locale)
      // verify required parameter 'ids' is not null or undefined
      assertParamExists("getArticlesAssets", "ids", ids)
      const localVarPath = `/videos/articles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (ids) {
        localVarQueryParameter["ids"] = ids
      }

      if (showGlobal != null) {
        localVarHeaderParameter["show-global"] =
          typeof showGlobal === "string" ? showGlobal : JSON.stringify(showGlobal)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArticleAssetsApi - functional programming interface
 * @export
 */
export const ArticleAssetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticleAssetsApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a locale and a list of article numbers ([SKUs](/glossary)) to retrieve document data for the available articles. You can refine the results by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for one or more articles.
     * @param {string} locale The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} ids The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>} [documentTypes] The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
     * @param {'Web' | 'Mobile'} [application] The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
     * @param {Array<string>} [languages] The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAssets(
      locale: string,
      ids: Array<string>,
      documentTypes?: Array<
        | "TDU"
        | "TMA"
        | "TLE"
        | "TPA"
        | "TMH"
        | "TKO"
        | "TBE"
        | "TSD"
        | "TBA"
        | "TPN"
        | "TKA"
        | "TSB"
        | "TDB"
        | "TAS"
        | "TIA"
        | "THB"
        | "TIN"
        | "TSH"
        | "TEB"
        | "TIF"
      >,
      application?: "Web" | "Mobile",
      languages?: Array<string>,
      showGlobal?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleDocuments>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAssets(
        locale,
        ids,
        documentTypes,
        application,
        languages,
        showGlobal,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and list of article numbers ([SKUs](/glossary)) to retrieve video data for the available articles.
     * @summary Get video data for one or more articles.
     * @param {string} locale The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} ids The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticlesAssets(
      locale: string,
      ids: Array<string>,
      showGlobal?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleVideos>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticlesAssets(locale, ids, showGlobal, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ArticleAssetsApi - factory interface
 * @export
 */
export const ArticleAssetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ArticleAssetsApiFp(configuration)
  return {
    /**
     * Specify a locale and a list of article numbers ([SKUs](/glossary)) to retrieve document data for the available articles. You can refine the results by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for one or more articles.
     * @param {ArticleAssetsApiGetArticleAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAssets(
      requestParameters: ArticleAssetsApiGetArticleAssetsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<ArticleDocuments>> {
      return localVarFp
        .getArticleAssets(
          requestParameters.locale,
          requestParameters.ids,
          requestParameters.documentTypes,
          requestParameters.application,
          requestParameters.languages,
          requestParameters.showGlobal,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and list of article numbers ([SKUs](/glossary)) to retrieve video data for the available articles.
     * @summary Get video data for one or more articles.
     * @param {ArticleAssetsApiGetArticlesAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticlesAssets(
      requestParameters: ArticleAssetsApiGetArticlesAssetsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<ArticleVideos>> {
      return localVarFp
        .getArticlesAssets(requestParameters.locale, requestParameters.ids, requestParameters.showGlobal, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getArticleAssets operation in ArticleAssetsApi.
 * @export
 * @interface ArticleAssetsApiGetArticleAssetsRequest
 */
export interface ArticleAssetsApiGetArticleAssetsRequest {
  /**
   * The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly locale: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly ids: Array<string>

  /**
   * The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
   * @type {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly documentTypes?: Array<
    | "TDU"
    | "TMA"
    | "TLE"
    | "TPA"
    | "TMH"
    | "TKO"
    | "TBE"
    | "TSD"
    | "TBA"
    | "TPN"
    | "TKA"
    | "TSB"
    | "TDB"
    | "TAS"
    | "TIA"
    | "THB"
    | "TIN"
    | "TSH"
    | "TEB"
    | "TIF"
  >

  /**
   * The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
   * @type {'Web' | 'Mobile'}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly application?: "Web" | "Mobile"

  /**
   * The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
   * @type {Array<string>}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly languages?: Array<string>

  /**
   * Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
   * @type {boolean}
   * @memberof ArticleAssetsApiGetArticleAssets
   */
  readonly showGlobal?: boolean
}

/**
 * Request parameters for getArticlesAssets operation in ArticleAssetsApi.
 * @export
 * @interface ArticleAssetsApiGetArticlesAssetsRequest
 */
export interface ArticleAssetsApiGetArticlesAssetsRequest {
  /**
   * The locale for which to retrieve the article assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ArticleAssetsApiGetArticlesAssets
   */
  readonly locale: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the article assets. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ArticleAssetsApiGetArticlesAssets
   */
  readonly ids: Array<string>

  /**
   * Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
   * @type {boolean}
   * @memberof ArticleAssetsApiGetArticlesAssets
   */
  readonly showGlobal?: boolean
}

/**
 * ArticleAssetsApi - object-oriented interface
 * @export
 * @class ArticleAssetsApi
 * @extends {BaseAPI}
 */
export class ArticleAssetsApi extends BaseAPI {
  /**
   * Specify a locale and a list of article numbers ([SKUs](/glossary)) to retrieve document data for the available articles. You can refine the results by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
   * @summary Get document data for one or more articles.
   * @param {ArticleAssetsApiGetArticleAssetsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAssetsApi
   */
  public getArticleAssets(requestParameters: ArticleAssetsApiGetArticleAssetsRequest, options?: AxiosRequestConfig) {
    return ArticleAssetsApiFp(this.configuration)
      .getArticleAssets(
        requestParameters.locale,
        requestParameters.ids,
        requestParameters.documentTypes,
        requestParameters.application,
        requestParameters.languages,
        requestParameters.showGlobal,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and list of article numbers ([SKUs](/glossary)) to retrieve video data for the available articles.
   * @summary Get video data for one or more articles.
   * @param {ArticleAssetsApiGetArticlesAssetsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAssetsApi
   */
  public getArticlesAssets(requestParameters: ArticleAssetsApiGetArticlesAssetsRequest, options?: AxiosRequestConfig) {
    return ArticleAssetsApiFp(this.configuration)
      .getArticlesAssets(requestParameters.locale, requestParameters.ids, requestParameters.showGlobal, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductAssetsApi - axios parameter creator
 * @export
 */
export const ProductAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a locale and a product ID to retrieve document data for the product. You can refine the result by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for a product.
     * @param {string} id The product ID for which you want to retrieve the product assets.
     * @param {string} locale The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>} [documentTypes] The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
     * @param {'Web' | 'Mobile'} [application] The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
     * @param {Array<string>} [languages] The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAssets: async (
      id: string,
      locale: string,
      documentTypes?: Array<
        | "TDU"
        | "TMA"
        | "TLE"
        | "TPA"
        | "TMH"
        | "TKO"
        | "TBE"
        | "TSD"
        | "TBA"
        | "TPN"
        | "TKA"
        | "TSB"
        | "TDB"
        | "TAS"
        | "TIA"
        | "THB"
        | "TIN"
        | "TSH"
        | "TEB"
        | "TIF"
      >,
      application?: "Web" | "Mobile",
      languages?: Array<string>,
      showGlobal?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getProductAssets", "id", id)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductAssets", "locale", locale)
      const localVarPath = `/documents/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (documentTypes) {
        localVarQueryParameter["documentTypes"] = documentTypes
      }

      if (application !== undefined) {
        localVarQueryParameter["application"] = application
      }

      if (languages) {
        localVarQueryParameter["languages"] = languages
      }

      if (showGlobal != null) {
        localVarHeaderParameter["show-global"] =
          typeof showGlobal === "string" ? showGlobal : JSON.stringify(showGlobal)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and a product ID to retrieve video data for the product.
     * @summary Get video data for a product.
     * @param {string} id The product ID for which you want to retrieve the product assets.
     * @param {string} locale The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAssets: async (
      id: string,
      locale: string,
      showGlobal?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getProductsAssets", "id", id)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsAssets", "locale", locale)
      const localVarPath = `/videos/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (showGlobal != null) {
        localVarHeaderParameter["show-global"] =
          typeof showGlobal === "string" ? showGlobal : JSON.stringify(showGlobal)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductAssetsApi - functional programming interface
 * @export
 */
export const ProductAssetsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductAssetsApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a locale and a product ID to retrieve document data for the product. You can refine the result by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for a product.
     * @param {string} id The product ID for which you want to retrieve the product assets.
     * @param {string} locale The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>} [documentTypes] The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
     * @param {'Web' | 'Mobile'} [application] The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
     * @param {Array<string>} [languages] The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAssets(
      id: string,
      locale: string,
      documentTypes?: Array<
        | "TDU"
        | "TMA"
        | "TLE"
        | "TPA"
        | "TMH"
        | "TKO"
        | "TBE"
        | "TSD"
        | "TBA"
        | "TPN"
        | "TKA"
        | "TSB"
        | "TDB"
        | "TAS"
        | "TIA"
        | "THB"
        | "TIN"
        | "TSH"
        | "TEB"
        | "TIF"
      >,
      application?: "Web" | "Mobile",
      languages?: Array<string>,
      showGlobal?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDocuments>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAssets(
        id,
        locale,
        documentTypes,
        application,
        languages,
        showGlobal,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and a product ID to retrieve video data for the product.
     * @summary Get video data for a product.
     * @param {string} id The product ID for which you want to retrieve the product assets.
     * @param {string} locale The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
     * @param {boolean} [showGlobal] Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsAssets(
      id: string,
      locale: string,
      showGlobal?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVideos>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAssets(id, locale, showGlobal, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductAssetsApi - factory interface
 * @export
 */
export const ProductAssetsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProductAssetsApiFp(configuration)
  return {
    /**
     * Specify a locale and a product ID to retrieve document data for the product. You can refine the result by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
     * @summary Get document data for a product.
     * @param {ProductAssetsApiGetProductAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAssets(
      requestParameters: ProductAssetsApiGetProductAssetsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ProductDocuments> {
      return localVarFp
        .getProductAssets(
          requestParameters.id,
          requestParameters.locale,
          requestParameters.documentTypes,
          requestParameters.application,
          requestParameters.languages,
          requestParameters.showGlobal,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and a product ID to retrieve video data for the product.
     * @summary Get video data for a product.
     * @param {ProductAssetsApiGetProductsAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAssets(
      requestParameters: ProductAssetsApiGetProductsAssetsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<ProductVideos>> {
      return localVarFp
        .getProductsAssets(requestParameters.id, requestParameters.locale, requestParameters.showGlobal, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getProductAssets operation in ProductAssetsApi.
 * @export
 * @interface ProductAssetsApiGetProductAssetsRequest
 */
export interface ProductAssetsApiGetProductAssetsRequest {
  /**
   * The product ID for which you want to retrieve the product assets.
   * @type {string}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly id: string

  /**
   * The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly locale: string

  /**
   * The types of documents you want to retrieve. If you leave this parameter empty, the API returns all available document types. Separate each value with a comma &#x60;,&#x60;.
   * @type {Array<'TDU' | 'TMA' | 'TLE' | 'TPA' | 'TMH' | 'TKO' | 'TBE' | 'TSD' | 'TBA' | 'TPN' | 'TKA' | 'TSB' | 'TDB' | 'TAS' | 'TIA' | 'THB' | 'TIN' | 'TSH' | 'TEB' | 'TIF'>}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly documentTypes?: Array<
    | "TDU"
    | "TMA"
    | "TLE"
    | "TPA"
    | "TMH"
    | "TKO"
    | "TBE"
    | "TSD"
    | "TBA"
    | "TPN"
    | "TKA"
    | "TSB"
    | "TDB"
    | "TAS"
    | "TIA"
    | "THB"
    | "TIN"
    | "TSH"
    | "TEB"
    | "TIF"
  >

  /**
   * The application type for which the format of the documents should be optimized. Available values are &#x60;Web&#x60; and &#x60;Mobile&#x60;, with &#x60;Web&#x60; being the default if you leave the parameter empty. If the format you requested isn\&#39;t available, the API returns documents for the other application, if available.
   * @type {'Web' | 'Mobile'}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly application?: "Web" | "Mobile"

  /**
   * The languages that the documents should support, seperated by a comma &#x60;,&#x60;. Leave empty to retrieve documents in all available languages.   Each language is represented by a two-letter [ISO 639-1 code](https://www.wikiwand.com/en/List_of_ISO_639-1_codes), for example &#x60;EN&#x60; or &#x60;en&#x60; represents English.
   * @type {Array<string>}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly languages?: Array<string>

  /**
   * Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
   * @type {boolean}
   * @memberof ProductAssetsApiGetProductAssets
   */
  readonly showGlobal?: boolean
}

/**
 * Request parameters for getProductsAssets operation in ProductAssetsApi.
 * @export
 * @interface ProductAssetsApiGetProductsAssetsRequest
 */
export interface ProductAssetsApiGetProductsAssetsRequest {
  /**
   * The product ID for which you want to retrieve the product assets.
   * @type {string}
   * @memberof ProductAssetsApiGetProductsAssets
   */
  readonly id: string

  /**
   * The locale for which to retrieve the product assets. The service returns text-based response values such as the document title in the language associated with the locale. If the value is unavailable in this language, the English version is returned, which is always available.   You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductAssetsApiGetProductsAssets
   */
  readonly locale: string

  /**
   * Set to true if you want to include group locales in the result. Data linked to these locales is not subject to local market suppression. The default value is false. Note that using this parameter requires you to have the necessary permissions – get in touch with us to review and update your authorization scope.
   * @type {boolean}
   * @memberof ProductAssetsApiGetProductsAssets
   */
  readonly showGlobal?: boolean
}

/**
 * ProductAssetsApi - object-oriented interface
 * @export
 * @class ProductAssetsApi
 * @extends {BaseAPI}
 */
export class ProductAssetsApi extends BaseAPI {
  /**
   * Specify a locale and a product ID to retrieve document data for the product. You can refine the result by specifying the types of documents you want, the languages they support, and a preferred format.   If you don\'t get the data you expect, for example TIF documents, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
   * @summary Get document data for a product.
   * @param {ProductAssetsApiGetProductAssetsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAssetsApi
   */
  public getProductAssets(requestParameters: ProductAssetsApiGetProductAssetsRequest, options?: AxiosRequestConfig) {
    return ProductAssetsApiFp(this.configuration)
      .getProductAssets(
        requestParameters.id,
        requestParameters.locale,
        requestParameters.documentTypes,
        requestParameters.application,
        requestParameters.languages,
        requestParameters.showGlobal,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and a product ID to retrieve video data for the product.
   * @summary Get video data for a product.
   * @param {ProductAssetsApiGetProductsAssetsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAssetsApi
   */
  public getProductsAssets(requestParameters: ProductAssetsApiGetProductsAssetsRequest, options?: AxiosRequestConfig) {
    return ProductAssetsApiFp(this.configuration)
      .getProductsAssets(requestParameters.id, requestParameters.locale, requestParameters.showGlobal, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
