/* tslint:disable */
/* eslint-disable */
/**
 * PDF Request
 * Use the PDF Request API to obtain product data sheets (PDF) as well as catalogs and sample catalogs (PDF) for the Geberit online catalog. While this API is the consumer-facing service for acquiring the mentioned PDFs, our PDF HTML API and PDF Generation API are used to generate the actual PDFs and their content.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from "./base"

/**
 *
 * @export
 * @interface AdditionalInformationListModel
 */
export interface AdditionalInformationListModel {
  /**
   *
   * @type {Array<AdditionalInformationModel>}
   * @memberof AdditionalInformationListModel
   */
  list?: Array<AdditionalInformationModel>
}
/**
 *
 * @export
 * @interface AdditionalInformationModel
 */
export interface AdditionalInformationModel {
  /**
   *
   * @type {Array<string>}
   * @memberof AdditionalInformationModel
   */
  items?: Array<string>
  /**
   *
   * @type {string}
   * @memberof AdditionalInformationModel
   * @deprecated
   */
  replaceStatus?: AdditionalInformationModelReplaceStatusEnum
}

export const AdditionalInformationModelReplaceStatusEnum = {
  By: "REPLACED_BY",
  For: "REPLACED_FOR",
} as const

export type AdditionalInformationModelReplaceStatusEnum =
  (typeof AdditionalInformationModelReplaceStatusEnum)[keyof typeof AdditionalInformationModelReplaceStatusEnum]

/**
 *
 * @export
 * @interface AppModel
 */
export interface AppModel {
  /**
   *
   * @type {string}
   * @memberof AppModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof AppModel
   */
  description?: string
  /**
   *
   * @type {Array<AppStore>}
   * @memberof AppModel
   */
  stores?: Array<AppStore>
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof AppModel
   */
  images?: Array<ProductImageModel>
}
/**
 *
 * @export
 * @interface AppStore
 */
export interface AppStore {
  /**
   *
   * @type {string}
   * @memberof AppStore
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof AppStore
   */
  name?: AppStoreNameEnum
}

export const AppStoreNameEnum = {
  Itunes: "itunes",
  GooglePlay: "googlePlay",
} as const

export type AppStoreNameEnum = (typeof AppStoreNameEnum)[keyof typeof AppStoreNameEnum]

/**
 *
 * @export
 * @interface AppsListModel
 */
export interface AppsListModel {
  /**
   *
   * @type {Array<AppModel>}
   * @memberof AppsListModel
   */
  list?: Array<AppModel>
}
/**
 *
 * @export
 * @interface ArticleListModel
 */
export interface ArticleListModel {
  /**
   *
   * @type {Array<ArticleModel>}
   * @memberof ArticleListModel
   */
  articlesList?: Array<ArticleModel>
  /**
   *
   * @type {Array<AttributeModel>}
   * @memberof ArticleListModel
   */
  attributesList?: Array<AttributeModel>
  /**
   *
   * @type {number}
   * @memberof ArticleListModel
   */
  articlesListSize?: number
}
/**
 *
 * @export
 * @interface ArticleModel
 */
export interface ArticleModel {
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  articleId?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  eanCode?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  description?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ArticleModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ArticleModel
   */
  cadDrawings?: SimpleAssetListModel
  /**
   *
   * @type {Array<AttributeValueModel>}
   * @memberof ArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
  /**
   *
   * @type {Array<EtimValueModel>}
   * @memberof ArticleModel
   */
  etimValues?: Array<EtimValueModel>
  /**
   *
   * @type {ProductStateModel}
   * @memberof ArticleModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  tenderTextProductId?: string
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ArticleModel
   */
  accessories?: ProductTileListModel
}
/**
 *
 * @export
 * @interface AttributeModel
 */
export interface AttributeModel {
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  name?: string
}
/**
 *
 * @export
 * @interface AttributeValueModel
 */
export interface AttributeValueModel {
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  rawValue?: string
  /**
   *
   * @type {Array<UnitModel>}
   * @memberof AttributeValueModel
   */
  unit?: Array<UnitModel>
}
/**
 *
 * @export
 * @interface BimAssetsModel
 */
export interface BimAssetsModel {
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof BimAssetsModel
   */
  list?: Array<ProductImageModel>
}
/**
 * Contains catalog data to include in the generated catalog PDF.
 * @export
 * @interface CatalogRequest
 */
export interface CatalogRequest {
  /**
   * The catalog title as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  title?: string
  /**
   * The catalog creator\'s name as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  creator?: string
  /**
   * The catalog\'s description as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  description?: string
  /**
   * The name of the wishlist associated with the catalog. Wishlist names are created by the user in the online catalog.
   * @type {string}
   * @memberof CatalogRequest
   */
  wishlistName?: string
  /**
   * The alternative template in which to render the catalog PDF.  Currently, the only alternative template is Pluvia (define as `catalog-pdf-pluvia` in your request), which adds a new page to the PDF with one image and two columns of text. You can define content for the Pluvia template via the `additionalData` attribute in the request body.
   * @type {string}
   * @memberof CatalogRequest
   */
  templateName?: string
  /**
   * A list of article numbers whose respective product data sheets to include in the catalog.
   * @type {Array<string>}
   * @memberof CatalogRequest
   */
  articleSkuList: Array<string>
  /**
   * Contains additional data that you can specify when you use an alternative template via the `templateName` attribute in the request body.  Currently, the only alternative template is Pluvia, which adds a new page to the catalog with an image and two columns of text. To provide the Pluvia template with data, it needs additional data as defined in this attribute\'s example.
   * @type {{ [key: string]: object; }}
   * @memberof CatalogRequest
   */
  additionalData?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
  /**
   *
   * @type {string}
   * @memberof CategoryModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof CategoryModel
   */
  name?: string
}
/**
 *
 * @export
 * @interface DeliveryInfoListModel
 */
export interface DeliveryInfoListModel {
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  included?: Array<LabelWithSequenceModel>
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  notIncluded?: Array<LabelWithSequenceModel>
  /**
   *
   * @type {boolean}
   * @memberof DeliveryInfoListModel
   */
  hasScopeOfDelivery?: boolean
}
/**
 *
 * @export
 * @interface DocumentListModel
 */
export interface DocumentListModel {
  /**
   *
   * @type {Array<DocumentModel>}
   * @memberof DocumentListModel
   */
  list?: Array<DocumentModel>
}
/**
 *
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  contentType?: string
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  url?: string
  /**
   *
   * @type {Set<string>}
   * @memberof DocumentModel
   */
  languages?: Set<string>
}
/**
 *
 * @export
 * @interface EtimFeatureModel
 */
export interface EtimFeatureModel {
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  name?: string
  /**
   *
   * @type {Array<string>}
   * @memberof EtimFeatureModel
   */
  values?: Array<string>
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  unit?: string
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  details?: string
}
/**
 *
 * @export
 * @interface EtimValueModel
 */
export interface EtimValueModel {
  /**
   *
   * @type {string}
   * @memberof EtimValueModel
   */
  details?: string
  /**
   *
   * @type {Array<string>}
   * @memberof EtimValueModel
   */
  version?: Array<string>
  /**
   *
   * @type {string}
   * @memberof EtimValueModel
   */
  classification?: string
  /**
   *
   * @type {Array<EtimFeatureModel>}
   * @memberof EtimValueModel
   */
  features?: Array<EtimFeatureModel>
}
/**
 *
 * @export
 * @interface LabelWithSequenceListModel
 */
export interface LabelWithSequenceListModel {
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof LabelWithSequenceListModel
   */
  list?: Array<LabelWithSequenceModel>
}
/**
 *
 * @export
 * @interface LabelWithSequenceModel
 */
export interface LabelWithSequenceModel {
  /**
   *
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  key?: string
  /**
   *
   * @type {number}
   * @memberof LabelWithSequenceModel
   */
  sequence?: number
}
/**
 * Contains the URL to the requested PDF.
 * @export
 * @interface PdfUrlResponse
 */
export interface PdfUrlResponse {
  /**
   * The URL to the requested PDF.
   * @type {string}
   * @memberof PdfUrlResponse
   */
  url?: string
}
/**
 *
 * @export
 * @interface ProductGalleryModel
 */
export interface ProductGalleryModel {
  /**
   *
   * @type {string}
   * @memberof ProductGalleryModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductGalleryModel
   */
  description?: string
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof ProductGalleryModel
   */
  list?: Array<ProductImageModel>
}
/**
 *
 * @export
 * @interface ProductImageModel
 */
export interface ProductImageModel {
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnailSmall?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnail?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  small?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  medium?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  large?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  original?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  orientation?: string
  /**
   *
   * @type {number}
   * @memberof ProductImageModel
   */
  sequence?: number
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  eps?: string
}
/**
 * Contains the JSON product data (the entire product schema) of all products included in the sample catalog. For example, you can retrieve the corresponding data via the Product API.
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  productId?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  brandName?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  productBrand?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  system?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  bleDevice?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {ProductVariantModel}
   * @memberof ProductModel
   */
  variant?: ProductVariantModel
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ArticleListModel}
   * @memberof ProductModel
   */
  articles?: ArticleListModel
  /**
   *
   * @type {TechnicalAttributesListModel}
   * @memberof ProductModel
   */
  technicalDataList?: TechnicalAttributesListModel
  /**
   *
   * @type {AdditionalInformationListModel}
   * @memberof ProductModel
   */
  additionalInformation?: AdditionalInformationListModel
  /**
   *
   * @type {DeliveryInfoListModel}
   * @memberof ProductModel
   */
  scopeOfDelivery?: DeliveryInfoListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  characteristics?: LabelWithSequenceListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  applicationPurposes?: LabelWithSequenceListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  accessories?: ProductTileListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  canBeCombinedWith?: ProductTileListModel
  /**
   *
   * @type {SparePartOverviewListModel}
   * @memberof ProductModel
   */
  spareParts?: SparePartOverviewListModel
  /**
   *
   * @type {DocumentListModel}
   * @memberof ProductModel
   */
  documents?: DocumentListModel
  /**
   *
   * @type {VideoListModel}
   * @memberof ProductModel
   */
  videos?: VideoListModel
  /**
   *
   * @type {AppsListModel}
   * @memberof ProductModel
   */
  apps?: AppsListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  accessoriesOverviews?: SimpleAssetListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  intendedUseOverviews?: SimpleAssetListModel
  /**
   *
   * @type {Set<CategoryModel>}
   * @memberof ProductModel
   */
  categories?: Set<CategoryModel>
  /**
   *
   * @type {BimAssetsModel}
   * @memberof ProductModel
   */
  bimAssets?: BimAssetsModel
}
/**
 *
 * @export
 * @interface ProductStateModel
 */
export interface ProductStateModel {
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  indicator?: ProductStateModelIndicatorEnum
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimRelevant?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimReady?: boolean
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  replacementFor?: string
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  replacedBy?: string
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  buyable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  orderRelated?: boolean
}

export const ProductStateModelIndicatorEnum = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type ProductStateModelIndicatorEnum =
  (typeof ProductStateModelIndicatorEnum)[keyof typeof ProductStateModelIndicatorEnum]

/**
 *
 * @export
 * @interface ProductTileListModel
 */
export interface ProductTileListModel {
  /**
   *
   * @type {Array<ProductTileModel>}
   * @memberof ProductTileListModel
   */
  list?: Array<ProductTileModel>
}
/**
 *
 * @export
 * @interface ProductTileModel
 */
export interface ProductTileModel {
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof ProductTileModel
   */
  numberArticles?: number
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductTileModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductTileModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  sku?: string
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  seriesName?: string
}
/**
 *
 * @export
 * @interface ProductVariantModel
 */
export interface ProductVariantModel {
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  sku?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  image?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  price?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  priceOld?: string
}
/**
 * Contains information about the product categories to which the products added to the sample catalog belong.
 * @export
 * @interface SampleCatalogCategory
 */
export interface SampleCatalogCategory {
  /**
   * The name of the category.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  name?: string
  /**
   * The category\'s slogan.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  slogan?: string
  /**
   * The category\'s image.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  image?: string
  /**
   * An `array` of product IDs that belong to this product category to include in the sample catalog.
   * @type {Array<string>}
   * @memberof SampleCatalogCategory
   */
  products: Array<string>
}
/**
 * Contains information about the sample catalog\'s groups as defined by the user.
 * @export
 * @interface SampleCatalogGroup
 */
export interface SampleCatalogGroup {
  /**
   * The group\'s title as defined by the user.
   * @type {string}
   * @memberof SampleCatalogGroup
   */
  title?: string
  /**
   * The group\'s description as defined by the user.
   * @type {string}
   * @memberof SampleCatalogGroup
   */
  description?: string
  /**
   * The group\'s image as defined by the user, `Base64` encoded. Images should be quadratic and may not exceed 1MB size.
   * @type {object}
   * @memberof SampleCatalogGroup
   */
  image?: object
  /**
   * An array of `SampleCatalogCategory` objects. Contains information about the product categories included in this group.
   * @type {Array<SampleCatalogCategory>}
   * @memberof SampleCatalogGroup
   */
  categories?: Array<SampleCatalogCategory>
}
/**
 * Contains catalog data to include in the generated sample catalog PDF.
 * @export
 * @interface SampleCatalogRequest
 */
export interface SampleCatalogRequest {
  /**
   * The catalog title as defined by the user.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  title?: string
  /**
   * The catalog\'s description as defined by the user.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  description?: string
  /**
   * An array of `SampleCatalogGroup` objects. Contains information about the sample catalog\'s groups.
   * @type {Array<SampleCatalogGroup>}
   * @memberof SampleCatalogRequest
   */
  groups: Array<SampleCatalogGroup>
  /**
   * Contains the JSON product data (the entire product schema) of all products included in the sample catalog. For example, you can retrieve the corresponding data via the Product API.
   * @type {{ [key: string]: ProductModel; }}
   * @memberof SampleCatalogRequest
   */
  products: { [key: string]: ProductModel }
  /**
   * A list of article numbers whose respective product data sheets to include in the sample catalog.
   * @type {Array<string>}
   * @memberof SampleCatalogRequest
   */
  articleSkuList: Array<string>
  /**
   * The email address to send the sample catalog\'s download link when the catalog is created on the online catalog website.  Please note, however, that the PDF Request API returns the PDF file of the sample catalog or its URL directly and does not send any emails.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  email?: string
}
/**
 *
 * @export
 * @interface SimpleAssetListModel
 */
export interface SimpleAssetListModel {
  /**
   *
   * @type {Array<SimpleAssetModel>}
   * @memberof SimpleAssetListModel
   */
  list?: Array<SimpleAssetModel>
}
/**
 *
 * @export
 * @interface SimpleAssetModel
 */
export interface SimpleAssetModel {
  /**
   *
   * @type {string}
   * @memberof SimpleAssetModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SimpleAssetModel
   */
  url?: string
}
/**
 *
 * @export
 * @interface SparePartArticleModel
 */
export interface SparePartArticleModel {
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {SparePartArticleStateModel}
   * @memberof SparePartArticleModel
   */
  state?: SparePartArticleStateModel
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  position?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  remarks?: string
  /**
   *
   * @type {Array<AttributeValueModel>}
   * @memberof SparePartArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
}
/**
 *
 * @export
 * @interface SparePartArticleStateModel
 */
export interface SparePartArticleStateModel {
  /**
   *
   * @type {boolean}
   * @memberof SparePartArticleStateModel
   */
  available?: boolean
  /**
   *
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacedBy?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacementFor?: string
}
/**
 *
 * @export
 * @interface SparePartArticlesListModel
 */
export interface SparePartArticlesListModel {
  /**
   *
   * @type {Array<SparePartArticleModel>}
   * @memberof SparePartArticlesListModel
   */
  sparePartArticlesList?: Array<SparePartArticleModel>
  /**
   *
   * @type {Array<AttributeModel>}
   * @memberof SparePartArticlesListModel
   */
  attributesList?: Array<AttributeModel>
}
/**
 *
 * @export
 * @interface SparePartOverviewListModel
 */
export interface SparePartOverviewListModel {
  /**
   *
   * @type {Array<SparePartOverviewModel>}
   * @memberof SparePartOverviewListModel
   */
  list?: Array<SparePartOverviewModel>
}
/**
 *
 * @export
 * @interface SparePartOverviewModel
 */
export interface SparePartOverviewModel {
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  productId?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  brandNames?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  types?: string
  /**
   *
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureFrom?: number
  /**
   *
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureTo?: number
  /**
   *
   * @type {ProductImageModel}
   * @memberof SparePartOverviewModel
   */
  image?: ProductImageModel
  /**
   *
   * @type {SparePartArticlesListModel}
   * @memberof SparePartOverviewModel
   */
  sparePartArticles?: SparePartArticlesListModel
}
/**
 *
 * @export
 * @interface TechnicalAttributesListModel
 */
export interface TechnicalAttributesListModel {
  /**
   *
   * @type {Array<TechnicalAttributesModel>}
   * @memberof TechnicalAttributesListModel
   */
  list?: Array<TechnicalAttributesModel>
}
/**
 *
 * @export
 * @interface TechnicalAttributesModel
 */
export interface TechnicalAttributesModel {
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  rawValue?: string
  /**
   *
   * @type {number}
   * @memberof TechnicalAttributesModel
   */
  sequence?: number
  /**
   *
   * @type {Array<UnitModel>}
   * @memberof TechnicalAttributesModel
   */
  unit?: Array<UnitModel>
}
/**
 *
 * @export
 * @interface UnitModel
 */
export interface UnitModel {
  /**
   *
   * @type {string}
   * @memberof UnitModel
   */
  encoding?: string
  /**
   *
   * @type {string}
   * @memberof UnitModel
   */
  value?: string
}
/**
 *
 * @export
 * @interface VideoListModel
 */
export interface VideoListModel {
  /**
   *
   * @type {Array<VideoModel>}
   * @memberof VideoListModel
   */
  list?: Array<VideoModel>
}
/**
 *
 * @export
 * @interface VideoModel
 */
export interface VideoModel {
  /**
   *
   * @type {string}
   * @memberof VideoModel
   */
  youtubeID?: string
}

/**
 * CatalogPDFApi - axios parameter creator
 * @export
 */
export const CatalogPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdf: async (
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedCatalogPdf", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedCatalogPdf", "brand", brand)
      const localVarPath = `/{locale}/{brand}/catalog/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdfUrl: async (
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedCatalogPdfUrl", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedCatalogPdfUrl", "brand", brand)
      const localVarPath = `/{locale}/{brand}/catalog/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdf: async (
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdf", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdf", "brand", brand)
      const localVarPath = `/{locale}/{brand}/sample-catalog/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdfUrl: async (
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdfUrl", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdfUrl", "brand", brand)
      const localVarPath = `/{locale}/{brand}/sample-catalog/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdf: async (
      locale: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getCatalogPdf", "locale", locale)
      const localVarPath = `/{locale}/catalog/pdf`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdfUrl: async (
      locale: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getCatalogPdfUrl", "locale", locale)
      const localVarPath = `/{locale}/catalog/pdf/url`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdf: async (
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSampleCatalogPdf", "locale", locale)
      const localVarPath = `/{locale}/sample-catalog/pdf`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdfUrl: async (
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSampleCatalogPdfUrl", "locale", locale)
      const localVarPath = `/{locale}/sample-catalog/pdf/url`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CatalogPDFApi - functional programming interface
 * @export
 */
export const CatalogPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CatalogPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedCatalogPdf(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedCatalogPdf(
        locale,
        brand,
        catalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedCatalogPdfUrl(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedCatalogPdfUrl(
        locale,
        brand,
        catalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSampleCatalogPdf(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSampleCatalogPdf(
        locale,
        brand,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSampleCatalogPdfUrl(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSampleCatalogPdfUrl(
        locale,
        brand,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogPdf(
      locale: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogPdf(locale, catalogRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogPdfUrl(
      locale: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogPdfUrl(locale, catalogRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSampleCatalogPdf(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleCatalogPdf(
        locale,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSampleCatalogPdfUrl(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleCatalogPdfUrl(
        locale,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CatalogPDFApi - factory interface
 * @export
 */
export const CatalogPDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CatalogPDFApiFp(configuration)
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {CatalogPDFApiGetBrandedCatalogPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdf(
      requestParameters: CatalogPDFApiGetBrandedCatalogPdfRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedCatalogPdf(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.catalogRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {CatalogPDFApiGetBrandedCatalogPdfUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdfUrl(
      requestParameters: CatalogPDFApiGetBrandedCatalogPdfUrlRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedCatalogPdfUrl(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.catalogRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {CatalogPDFApiGetBrandedSampleCatalogPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdf(
      requestParameters: CatalogPDFApiGetBrandedSampleCatalogPdfRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedSampleCatalogPdf(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.sampleCatalogRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdfUrl(
      requestParameters: CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedSampleCatalogPdfUrl(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.sampleCatalogRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {CatalogPDFApiGetCatalogPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdf(
      requestParameters: CatalogPDFApiGetCatalogPdfRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getCatalogPdf(requestParameters.locale, requestParameters.catalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {CatalogPDFApiGetCatalogPdfUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdfUrl(
      requestParameters: CatalogPDFApiGetCatalogPdfUrlRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getCatalogPdfUrl(requestParameters.locale, requestParameters.catalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {CatalogPDFApiGetSampleCatalogPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdf(
      requestParameters: CatalogPDFApiGetSampleCatalogPdfRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getSampleCatalogPdf(requestParameters.locale, requestParameters.sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {CatalogPDFApiGetSampleCatalogPdfUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdfUrl(
      requestParameters: CatalogPDFApiGetSampleCatalogPdfUrlRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getSampleCatalogPdfUrl(requestParameters.locale, requestParameters.sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getBrandedCatalogPdf operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetBrandedCatalogPdfRequest
 */
export interface CatalogPDFApiGetBrandedCatalogPdfRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedCatalogPdf
   */
  readonly locale: string

  /**
   * The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedCatalogPdf
   */
  readonly brand: string

  /**
   * Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {CatalogRequest}
   * @memberof CatalogPDFApiGetBrandedCatalogPdf
   */
  readonly catalogRequest?: CatalogRequest
}

/**
 * Request parameters for getBrandedCatalogPdfUrl operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetBrandedCatalogPdfUrlRequest
 */
export interface CatalogPDFApiGetBrandedCatalogPdfUrlRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedCatalogPdfUrl
   */
  readonly locale: string

  /**
   * The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedCatalogPdfUrl
   */
  readonly brand: string

  /**
   * Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {CatalogRequest}
   * @memberof CatalogPDFApiGetBrandedCatalogPdfUrl
   */
  readonly catalogRequest?: CatalogRequest
}

/**
 * Request parameters for getBrandedSampleCatalogPdf operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetBrandedSampleCatalogPdfRequest
 */
export interface CatalogPDFApiGetBrandedSampleCatalogPdfRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdf
   */
  readonly locale: string

  /**
   * The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdf
   */
  readonly brand: string

  /**
   * Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {SampleCatalogRequest}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdf
   */
  readonly sampleCatalogRequest?: SampleCatalogRequest
}

/**
 * Request parameters for getBrandedSampleCatalogPdfUrl operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest
 */
export interface CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdfUrl
   */
  readonly locale: string

  /**
   * The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdfUrl
   */
  readonly brand: string

  /**
   * Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {SampleCatalogRequest}
   * @memberof CatalogPDFApiGetBrandedSampleCatalogPdfUrl
   */
  readonly sampleCatalogRequest?: SampleCatalogRequest
}

/**
 * Request parameters for getCatalogPdf operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetCatalogPdfRequest
 */
export interface CatalogPDFApiGetCatalogPdfRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetCatalogPdf
   */
  readonly locale: string

  /**
   * Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {CatalogRequest}
   * @memberof CatalogPDFApiGetCatalogPdf
   */
  readonly catalogRequest?: CatalogRequest
}

/**
 * Request parameters for getCatalogPdfUrl operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetCatalogPdfUrlRequest
 */
export interface CatalogPDFApiGetCatalogPdfUrlRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetCatalogPdfUrl
   */
  readonly locale: string

  /**
   * Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {CatalogRequest}
   * @memberof CatalogPDFApiGetCatalogPdfUrl
   */
  readonly catalogRequest?: CatalogRequest
}

/**
 * Request parameters for getSampleCatalogPdf operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetSampleCatalogPdfRequest
 */
export interface CatalogPDFApiGetSampleCatalogPdfRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetSampleCatalogPdf
   */
  readonly locale: string

  /**
   * Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {SampleCatalogRequest}
   * @memberof CatalogPDFApiGetSampleCatalogPdf
   */
  readonly sampleCatalogRequest?: SampleCatalogRequest
}

/**
 * Request parameters for getSampleCatalogPdfUrl operation in CatalogPDFApi.
 * @export
 * @interface CatalogPDFApiGetSampleCatalogPdfUrlRequest
 */
export interface CatalogPDFApiGetSampleCatalogPdfUrlRequest {
  /**
   * The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof CatalogPDFApiGetSampleCatalogPdfUrl
   */
  readonly locale: string

  /**
   * Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @type {SampleCatalogRequest}
   * @memberof CatalogPDFApiGetSampleCatalogPdfUrl
   */
  readonly sampleCatalogRequest?: SampleCatalogRequest
}

/**
 * CatalogPDFApi - object-oriented interface
 * @export
 * @class CatalogPDFApi
 * @extends {BaseAPI}
 */
export class CatalogPDFApi extends BaseAPI {
  /**
   * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create and get branded catalog.
   * @param {CatalogPDFApiGetBrandedCatalogPdfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedCatalogPdf(
    requestParameters: CatalogPDFApiGetBrandedCatalogPdfRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedCatalogPdf(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.catalogRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create branded catalog and get URL.
   * @param {CatalogPDFApiGetBrandedCatalogPdfUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedCatalogPdfUrl(
    requestParameters: CatalogPDFApiGetBrandedCatalogPdfUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedCatalogPdfUrl(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.catalogRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create and get branded sample catalog.
   * @param {CatalogPDFApiGetBrandedSampleCatalogPdfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedSampleCatalogPdf(
    requestParameters: CatalogPDFApiGetBrandedSampleCatalogPdfRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedSampleCatalogPdf(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.sampleCatalogRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create branded sample catalog and get URL.
   * @param {CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedSampleCatalogPdfUrl(
    requestParameters: CatalogPDFApiGetBrandedSampleCatalogPdfUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedSampleCatalogPdfUrl(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.sampleCatalogRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create and get catalog.
   * @param {CatalogPDFApiGetCatalogPdfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getCatalogPdf(requestParameters: CatalogPDFApiGetCatalogPdfRequest, options?: AxiosRequestConfig) {
    return CatalogPDFApiFp(this.configuration)
      .getCatalogPdf(requestParameters.locale, requestParameters.catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create catalog and get URL.
   * @param {CatalogPDFApiGetCatalogPdfUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getCatalogPdfUrl(requestParameters: CatalogPDFApiGetCatalogPdfUrlRequest, options?: AxiosRequestConfig) {
    return CatalogPDFApiFp(this.configuration)
      .getCatalogPdfUrl(requestParameters.locale, requestParameters.catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create and get sample catalog.
   * @param {CatalogPDFApiGetSampleCatalogPdfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getSampleCatalogPdf(requestParameters: CatalogPDFApiGetSampleCatalogPdfRequest, options?: AxiosRequestConfig) {
    return CatalogPDFApiFp(this.configuration)
      .getSampleCatalogPdf(requestParameters.locale, requestParameters.sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create sample catalog and get URL.
   * @param {CatalogPDFApiGetSampleCatalogPdfUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getSampleCatalogPdfUrl(
    requestParameters: CatalogPDFApiGetSampleCatalogPdfUrlRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getSampleCatalogPdfUrl(requestParameters.locale, requestParameters.sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductPDFApi - axios parameter creator
 * @export
 */
export const ProductPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/products/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfBySku: async (
      locale: string,
      brand: string,
      sku: string,
      singleArticle?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "sku", sku)
      const localVarPath = `/{locale}/{brand}/articles/{sku}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (singleArticle !== undefined) {
        localVarQueryParameter["singleArticle"] = singleArticle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/products/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlBySku: async (
      locale: string,
      brand: string,
      sku: string,
      singleArticle?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "sku", sku)
      const localVarPath = `/{locale}/{brand}/articles/{sku}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (singleArticle !== undefined) {
        localVarQueryParameter["singleArticle"] = singleArticle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get branded product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} brand The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.\&quot;  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductsPdfBySkus: async (
      locale: string,
      brand: string,
      skus: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductsPdfBySkus", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductsPdfBySkus", "brand", brand)
      // verify required parameter 'skus' is not null or undefined
      assertParamExists("getBrandedProductsPdfBySkus", "skus", skus)
      const localVarPath = `/{locale}/{brand}/articles/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (skus) {
        localVarQueryParameter["skus"] = skus
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve the URL to a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to branded product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} brand The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductsPdfUrlBySkus: async (
      locale: string,
      brand: string,
      skus: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductsPdfUrlBySkus", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductsPdfUrlBySkus", "brand", brand)
      // verify required parameter 'skus' is not null or undefined
      assertParamExists("getBrandedProductsPdfUrlBySkus", "skus", skus)
      const localVarPath = `/{locale}/{brand}/articles/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (skus) {
        localVarQueryParameter["skus"] = skus
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfByKey: async (locale: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductPdfByKey", "key", key)
      const localVarPath = `/{locale}/products/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfBySku: async (
      locale: string,
      sku: string,
      singleArticle?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfBySku", "locale", locale)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductPdfBySku", "sku", sku)
      const localVarPath = `/{locale}/articles/{sku}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (singleArticle !== undefined) {
        localVarQueryParameter["singleArticle"] = singleArticle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfUrlByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/products/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlBySku: async (
      locale: string,
      sku: string,
      singleArticle?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfUrlBySku", "locale", locale)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductPdfUrlBySku", "sku", sku)
      const localVarPath = `/{locale}/articles/{sku}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (singleArticle !== undefined) {
        localVarQueryParameter["singleArticle"] = singleArticle
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide a list of article numbers and a locale to retrieve a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.\&quot;  You can find a list of available locales in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. \&quot;You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {string} [xAvailabilityGroupKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsPdfBySkus: async (
      locale: string,
      skus: Array<string>,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsPdfBySkus", "locale", locale)
      // verify required parameter 'skus' is not null or undefined
      assertParamExists("getProductsPdfBySkus", "skus", skus)
      const localVarPath = `/{locale}/articles/pdf`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (skus) {
        localVarQueryParameter["skus"] = skus
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide a list of article numbers and a locale to retrieve the URL to a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsPdfUrlBySkus: async (
      locale: string,
      skus: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsPdfUrlBySkus", "locale", locale)
      // verify required parameter 'skus' is not null or undefined
      assertParamExists("getProductsPdfUrlBySkus", "skus", skus)
      const localVarPath = `/{locale}/articles/pdf/url`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (skus) {
        localVarQueryParameter["skus"] = skus
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductPDFApi - functional programming interface
 * @export
 */
export const ProductPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfByKey(locale, brand, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfBySku(
      locale: string,
      brand: string,
      sku: string,
      singleArticle?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfBySku(
        locale,
        brand,
        sku,
        singleArticle,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfUrlByKey(
        locale,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfUrlBySku(
      locale: string,
      brand: string,
      sku: string,
      singleArticle?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfUrlBySku(
        locale,
        brand,
        sku,
        singleArticle,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get branded product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} brand The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.\&quot;  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductsPdfBySkus(
      locale: string,
      brand: string,
      skus: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductsPdfBySkus(
        locale,
        brand,
        skus,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve the URL to a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to branded product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} brand The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductsPdfUrlBySkus(
      locale: string,
      brand: string,
      skus: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductsPdfUrlBySkus(
        locale,
        brand,
        skus,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfBySku(
      locale: string,
      sku: string,
      singleArticle?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfBySku(locale, sku, singleArticle, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfUrlByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfUrlByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {boolean} [singleArticle] Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfUrlBySku(
      locale: string,
      sku: string,
      singleArticle?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfUrlBySku(
        locale,
        sku,
        singleArticle,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide a list of article numbers and a locale to retrieve a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.\&quot;  You can find a list of available locales in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. \&quot;You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {string} [xAvailabilityGroupKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsPdfBySkus(
      locale: string,
      skus: Array<string>,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsPdfBySkus(
        locale,
        skus,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide a list of article numbers and a locale to retrieve the URL to a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to product data sheet(s) containing specific article numbers.
     * @param {string} locale The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {Array<string>} skus The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsPdfUrlBySkus(
      locale: string,
      skus: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsPdfUrlBySkus(locale, skus, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductPDFApi - factory interface
 * @export
 */
export const ProductPDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProductPDFApiFp(configuration)
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {ProductPDFApiGetBrandedProductPdfByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfByKey(
      requestParameters: ProductPDFApiGetBrandedProductPdfByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedProductPdfByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {ProductPDFApiGetBrandedProductPdfBySkuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfBySku(
      requestParameters: ProductPDFApiGetBrandedProductPdfBySkuRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedProductPdfBySku(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.sku,
          requestParameters.singleArticle,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {ProductPDFApiGetBrandedProductPdfUrlByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlByKey(
      requestParameters: ProductPDFApiGetBrandedProductPdfUrlByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedProductPdfUrlByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {ProductPDFApiGetBrandedProductPdfUrlBySkuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlBySku(
      requestParameters: ProductPDFApiGetBrandedProductPdfUrlBySkuRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedProductPdfUrlBySku(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.sku,
          requestParameters.singleArticle,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get branded product data sheet(s) containing specific article numbers.
     * @param {ProductPDFApiGetBrandedProductsPdfBySkusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductsPdfBySkus(
      requestParameters: ProductPDFApiGetBrandedProductsPdfBySkusRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedProductsPdfBySkus(requestParameters.locale, requestParameters.brand, requestParameters.skus, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Provide a list of article numbers, along with a locale and brand, to retrieve the URL to a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to branded product data sheet(s) containing specific article numbers.
     * @param {ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductsPdfUrlBySkus(
      requestParameters: ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedProductsPdfUrlBySkus(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.skus,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {ProductPDFApiGetProductPdfByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfByKey(
      requestParameters: ProductPDFApiGetProductPdfByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getProductPdfByKey(requestParameters.locale, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {ProductPDFApiGetProductPdfBySkuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfBySku(
      requestParameters: ProductPDFApiGetProductPdfBySkuRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getProductPdfBySku(requestParameters.locale, requestParameters.sku, requestParameters.singleArticle, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {ProductPDFApiGetProductPdfUrlByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlByKey(
      requestParameters: ProductPDFApiGetProductPdfUrlByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getProductPdfUrlByKey(requestParameters.locale, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {ProductPDFApiGetProductPdfUrlBySkuRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlBySku(
      requestParameters: ProductPDFApiGetProductPdfUrlBySkuRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getProductPdfUrlBySku(
          requestParameters.locale,
          requestParameters.sku,
          requestParameters.singleArticle,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Provide a list of article numbers and a locale to retrieve a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get product data sheet(s) containing specific article numbers.
     * @param {ProductPDFApiGetProductsPdfBySkusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsPdfBySkus(
      requestParameters: ProductPDFApiGetProductsPdfBySkusRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getProductsPdfBySkus(
          requestParameters.locale,
          requestParameters.skus,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Provide a list of article numbers and a locale to retrieve the URL to a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
     * @summary Get URL to product data sheet(s) containing specific article numbers.
     * @param {ProductPDFApiGetProductsPdfUrlBySkusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsPdfUrlBySkus(
      requestParameters: ProductPDFApiGetProductsPdfUrlBySkusRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getProductsPdfUrlBySkus(requestParameters.locale, requestParameters.skus, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getBrandedProductPdfByKey operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductPdfByKeyRequest
 */
export interface ProductPDFApiGetBrandedProductPdfByKeyRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfByKey
   */
  readonly locale: string

  /**
   * The product\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfByKey
   */
  readonly brand: string

  /**
   * The product\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfByKey
   */
  readonly key: string
}

/**
 * Request parameters for getBrandedProductPdfBySku operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductPdfBySkuRequest
 */
export interface ProductPDFApiGetBrandedProductPdfBySkuRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfBySku
   */
  readonly locale: string

  /**
   * The product\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfBySku
   */
  readonly brand: string

  /**
   * The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfBySku
   */
  readonly sku: string

  /**
   * Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
   * @type {boolean}
   * @memberof ProductPDFApiGetBrandedProductPdfBySku
   */
  readonly singleArticle?: boolean
}

/**
 * Request parameters for getBrandedProductPdfUrlByKey operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductPdfUrlByKeyRequest
 */
export interface ProductPDFApiGetBrandedProductPdfUrlByKeyRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlByKey
   */
  readonly locale: string

  /**
   * The product\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlByKey
   */
  readonly brand: string

  /**
   * The product\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlByKey
   */
  readonly key: string
}

/**
 * Request parameters for getBrandedProductPdfUrlBySku operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductPdfUrlBySkuRequest
 */
export interface ProductPDFApiGetBrandedProductPdfUrlBySkuRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlBySku
   */
  readonly locale: string

  /**
   * The product\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlBySku
   */
  readonly brand: string

  /**
   * The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlBySku
   */
  readonly sku: string

  /**
   * Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
   * @type {boolean}
   * @memberof ProductPDFApiGetBrandedProductPdfUrlBySku
   */
  readonly singleArticle?: boolean
}

/**
 * Request parameters for getBrandedProductsPdfBySkus operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductsPdfBySkusRequest
 */
export interface ProductPDFApiGetBrandedProductsPdfBySkusRequest {
  /**
   * The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductsPdfBySkus
   */
  readonly locale: string

  /**
   * The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.\&quot;  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductsPdfBySkus
   */
  readonly brand: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ProductPDFApiGetBrandedProductsPdfBySkus
   */
  readonly skus: Array<string>
}

/**
 * Request parameters for getBrandedProductsPdfUrlBySkus operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest
 */
export interface ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest {
  /**
   * The articles locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductsPdfUrlBySkus
   */
  readonly locale: string

  /**
   * The articles\&#39; brand. The PDF HTML API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetBrandedProductsPdfUrlBySkus
   */
  readonly brand: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ProductPDFApiGetBrandedProductsPdfUrlBySkus
   */
  readonly skus: Array<string>
}

/**
 * Request parameters for getProductPdfByKey operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductPdfByKeyRequest
 */
export interface ProductPDFApiGetProductPdfByKeyRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfByKey
   */
  readonly locale: string

  /**
   * The product\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfByKey
   */
  readonly key: string
}

/**
 * Request parameters for getProductPdfBySku operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductPdfBySkuRequest
 */
export interface ProductPDFApiGetProductPdfBySkuRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfBySku
   */
  readonly locale: string

  /**
   * The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfBySku
   */
  readonly sku: string

  /**
   * Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
   * @type {boolean}
   * @memberof ProductPDFApiGetProductPdfBySku
   */
  readonly singleArticle?: boolean
}

/**
 * Request parameters for getProductPdfUrlByKey operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductPdfUrlByKeyRequest
 */
export interface ProductPDFApiGetProductPdfUrlByKeyRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfUrlByKey
   */
  readonly locale: string

  /**
   * The product\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfUrlByKey
   */
  readonly key: string
}

/**
 * Request parameters for getProductPdfUrlBySku operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductPdfUrlBySkuRequest
 */
export interface ProductPDFApiGetProductPdfUrlBySkuRequest {
  /**
   * The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfUrlBySku
   */
  readonly locale: string

  /**
   * The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @type {string}
   * @memberof ProductPDFApiGetProductPdfUrlBySku
   */
  readonly sku: string

  /**
   * Indicates whether the service should return a product data sheet HTML with single article information. The default value is &#x60;false&#x60;.
   * @type {boolean}
   * @memberof ProductPDFApiGetProductPdfUrlBySku
   */
  readonly singleArticle?: boolean
}

/**
 * Request parameters for getProductsPdfBySkus operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductsPdfBySkusRequest
 */
export interface ProductPDFApiGetProductsPdfBySkusRequest {
  /**
   * The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.\&quot;  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductsPdfBySkus
   */
  readonly locale: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. \&quot;You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ProductPDFApiGetProductsPdfBySkus
   */
  readonly skus: Array<string>

  /**
   *
   * @type {string}
   * @memberof ProductPDFApiGetProductsPdfBySkus
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getProductsPdfUrlBySkus operation in ProductPDFApi.
 * @export
 * @interface ProductPDFApiGetProductsPdfUrlBySkusRequest
 */
export interface ProductPDFApiGetProductsPdfUrlBySkusRequest {
  /**
   * The articles\&#39; locale. The PDF HTML API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ProductPDFApiGetProductsPdfUrlBySkus
   */
  readonly locale: string

  /**
   * The article numbers ([SKUs](/glossary)) for which you want to retrieve the PDF containing the associated product data sheet(s). Each data sheet only includes the article numbers provided by you. If an article is associated with multiple products, the service creates a data sheet for each product and combines them into a single PDF. You can specify up to 100 article numbers but must define at least one. Separate each article number with a comma &#x60;,&#x60;.
   * @type {Array<string>}
   * @memberof ProductPDFApiGetProductsPdfUrlBySkus
   */
  readonly skus: Array<string>
}

/**
 * ProductPDFApi - object-oriented interface
 * @export
 * @class ProductPDFApi
 * @extends {BaseAPI}
 */
export class ProductPDFApi extends BaseAPI {
  /**
   * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet by ID.
   * @param {ProductPDFApiGetBrandedProductPdfByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfByKey(
    requestParameters: ProductPDFApiGetBrandedProductPdfByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet by article number.
   * @param {ProductPDFApiGetBrandedProductPdfBySkuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfBySku(
    requestParameters: ProductPDFApiGetBrandedProductPdfBySkuRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfBySku(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.sku,
        requestParameters.singleArticle,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet URL by ID.
   * @param {ProductPDFApiGetBrandedProductPdfUrlByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfUrlByKey(
    requestParameters: ProductPDFApiGetBrandedProductPdfUrlByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfUrlByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet URL by article number.
   * @param {ProductPDFApiGetBrandedProductPdfUrlBySkuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfUrlBySku(
    requestParameters: ProductPDFApiGetBrandedProductPdfUrlBySkuRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfUrlBySku(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.sku,
        requestParameters.singleArticle,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide a list of article numbers, along with a locale and brand, to retrieve a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
   * @summary Get branded product data sheet(s) containing specific article numbers.
   * @param {ProductPDFApiGetBrandedProductsPdfBySkusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductsPdfBySkus(
    requestParameters: ProductPDFApiGetBrandedProductsPdfBySkusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductsPdfBySkus(requestParameters.locale, requestParameters.brand, requestParameters.skus, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide a list of article numbers, along with a locale and brand, to retrieve the URL to a PDF that contains branded product data sheet(s) for the given articles only. The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
   * @summary Get URL to branded product data sheet(s) containing specific article numbers.
   * @param {ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductsPdfUrlBySkus(
    requestParameters: ProductPDFApiGetBrandedProductsPdfUrlBySkusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductsPdfUrlBySkus(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.skus,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet by ID.
   * @param {ProductPDFApiGetProductPdfByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfByKey(requestParameters: ProductPDFApiGetProductPdfByKeyRequest, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfByKey(requestParameters.locale, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet by article number.
   * @param {ProductPDFApiGetProductPdfBySkuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfBySku(requestParameters: ProductPDFApiGetProductPdfBySkuRequest, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfBySku(requestParameters.locale, requestParameters.sku, requestParameters.singleArticle, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet URL by ID.
   * @param {ProductPDFApiGetProductPdfUrlByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfUrlByKey(
    requestParameters: ProductPDFApiGetProductPdfUrlByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfUrlByKey(requestParameters.locale, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet URL by article number.
   * @param {ProductPDFApiGetProductPdfUrlBySkuRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfUrlBySku(
    requestParameters: ProductPDFApiGetProductPdfUrlBySkuRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfUrlBySku(requestParameters.locale, requestParameters.sku, requestParameters.singleArticle, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide a list of article numbers and a locale to retrieve a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
   * @summary Get product data sheet(s) containing specific article numbers.
   * @param {ProductPDFApiGetProductsPdfBySkusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductsPdfBySkus(
    requestParameters: ProductPDFApiGetProductsPdfBySkusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getProductsPdfBySkus(
        requestParameters.locale,
        requestParameters.skus,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide a list of article numbers and a locale to retrieve the URL to a PDF that contains product data sheet(s) for the given articles only. The product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.  Note the following: - If an article is not available for the specified locale, it is not included in the data sheet. However, you can override the availability rules using the respective header parameter if you want to include it anyway. Please [contact us](mailto:developer@geberit.com) if you need assistance with this. - If an article number belongs to multiple products, the PDF contains separate product data sheet pages for each product.
   * @summary Get URL to product data sheet(s) containing specific article numbers.
   * @param {ProductPDFApiGetProductsPdfUrlBySkusRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductsPdfUrlBySkus(
    requestParameters: ProductPDFApiGetProductsPdfUrlBySkusRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductPDFApiFp(this.configuration)
      .getProductsPdfUrlBySkus(requestParameters.locale, requestParameters.skus, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SparePartsPDFApi - axios parameter creator
 * @export
 */
export const SparePartsPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/spare-parts/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfUrlByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/spare-parts/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartPdfByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartPdfByKey", "key", key)
      const localVarPath = `/{locale}/spare-parts/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfUrlByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartPdfUrlByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/spare-parts/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SparePartsPDFApi - functional programming interface
 * @export
 */
export const SparePartsPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SparePartsPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSparePartPdfByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSparePartPdfByKey(locale, brand, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSparePartPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSparePartPdfUrlByKey(
        locale,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartPdfByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartPdfByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartPdfUrlByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartPdfUrlByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SparePartsPDFApi - factory interface
 * @export
 */
export const SparePartsPDFApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SparePartsPDFApiFp(configuration)
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfByKey(
      requestParameters: SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedSparePartPdfByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfUrlByKey(
      requestParameters: SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedSparePartPdfUrlByKey(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {SparePartsPDFApiGetSparePartPdfByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfByKey(
      requestParameters: SparePartsPDFApiGetSparePartPdfByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .getSparePartPdfByKey(requestParameters.locale, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {SparePartsPDFApiGetSparePartPdfUrlByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfUrlByKey(
      requestParameters: SparePartsPDFApiGetSparePartPdfUrlByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getSparePartPdfUrlByKey(requestParameters.locale, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getBrandedSparePartPdfByKey operation in SparePartsPDFApi.
 * @export
 * @interface SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest
 */
export interface SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest {
  /**
   * The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfByKey
   */
  readonly locale: string

  /**
   * The spare part\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfByKey
   */
  readonly brand: string

  /**
   * The spare part\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfByKey
   */
  readonly key: string
}

/**
 * Request parameters for getBrandedSparePartPdfUrlByKey operation in SparePartsPDFApi.
 * @export
 * @interface SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest
 */
export interface SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest {
  /**
   * The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfUrlByKey
   */
  readonly locale: string

  /**
   * The spare part\&#39;s brand. The PDF Request API supports all available brands.
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfUrlByKey
   */
  readonly brand: string

  /**
   * The spare part\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof SparePartsPDFApiGetBrandedSparePartPdfUrlByKey
   */
  readonly key: string
}

/**
 * Request parameters for getSparePartPdfByKey operation in SparePartsPDFApi.
 * @export
 * @interface SparePartsPDFApiGetSparePartPdfByKeyRequest
 */
export interface SparePartsPDFApiGetSparePartPdfByKeyRequest {
  /**
   * The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof SparePartsPDFApiGetSparePartPdfByKey
   */
  readonly locale: string

  /**
   * The spare part\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof SparePartsPDFApiGetSparePartPdfByKey
   */
  readonly key: string
}

/**
 * Request parameters for getSparePartPdfUrlByKey operation in SparePartsPDFApi.
 * @export
 * @interface SparePartsPDFApiGetSparePartPdfUrlByKeyRequest
 */
export interface SparePartsPDFApiGetSparePartPdfUrlByKeyRequest {
  /**
   * The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @type {string}
   * @memberof SparePartsPDFApiGetSparePartPdfUrlByKey
   */
  readonly locale: string

  /**
   * The spare part\&#39;s ID (formerly called key).
   * @type {string}
   * @memberof SparePartsPDFApiGetSparePartPdfUrlByKey
   */
  readonly key: string
}

/**
 * SparePartsPDFApi - object-oriented interface
 * @export
 * @class SparePartsPDFApi
 * @extends {BaseAPI}
 */
export class SparePartsPDFApi extends BaseAPI {
  /**
   * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get branded spare parts data sheet by ID.
   * @param {SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getBrandedSparePartPdfByKey(
    requestParameters: SparePartsPDFApiGetBrandedSparePartPdfByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsPDFApiFp(this.configuration)
      .getBrandedSparePartPdfByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get branded spare parts data sheet URL by ID.
   * @param {SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getBrandedSparePartPdfUrlByKey(
    requestParameters: SparePartsPDFApiGetBrandedSparePartPdfUrlByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsPDFApiFp(this.configuration)
      .getBrandedSparePartPdfUrlByKey(requestParameters.locale, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get spare parts data sheet by ID.
   * @param {SparePartsPDFApiGetSparePartPdfByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getSparePartPdfByKey(
    requestParameters: SparePartsPDFApiGetSparePartPdfByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsPDFApiFp(this.configuration)
      .getSparePartPdfByKey(requestParameters.locale, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get spare parts data sheet URL by ID.
   * @param {SparePartsPDFApiGetSparePartPdfUrlByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getSparePartPdfUrlByKey(
    requestParameters: SparePartsPDFApiGetSparePartPdfUrlByKeyRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsPDFApiFp(this.configuration)
      .getSparePartPdfUrlByKey(requestParameters.locale, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
