import { Flex, ImageProps, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { brands } from "../../../../common/constants/constants"
import { extractCountryCode } from "../../../../common/utils/locale-utils"
import CustomImage from "../../../shared/CustomImage"

type LogoProps = Pick<ImageProps, "src" | "width" | "height" | "mt">

export const CompanyLogo = () => {
  const { query, locale } = useRouter()
  const brand = query.brand as string

  const getLogoProps = (): LogoProps => {
    let brandLogoProps = {}

    /* GEBERIT */
    if (brand === brands.geberit.value) {
      brandLogoProps = {
        src: "/logos/geberit-logo.svg",
        width: { base: "115px", md: "37" },
        height: { base: "4", md: "6" },
      }
      /* PORSGRUND */
    } else if (brand === brands.porsgrund.value) {
      brandLogoProps = {
        src: "/logos/porsgrund-logo.png",
        width: { base: "30.5", md: "40.5" },
        height: { base: "6", md: "8" },
      }
      /* IDO */
    } else if (brand === brands.ido.value) {
      brandLogoProps = {
        src: "/logos/ido-logo.png",
        width: { base: "101px", md: "153px" },
        height: { base: "8", md: "12" },
      }
      /* IFO */
    } else if (brand === brands.ifo.value) {
      brandLogoProps = {
        src: "/logos/ifo-logo.png",
        width: { base: "9", md: "16" },
        height: { base: "8", md: "14" },
      }
      /* KOLO */
    } else if (brand === brands.kolo.value) {
      brandLogoProps = {
        src: "/logos/kolo-logo.svg",
        width: { base: "77px", md: "99px" },
        height: { base: "8", md: "10" },
      }
      /* Twyford */
    } else if (brand === brands.twyford.value) {
      brandLogoProps = {
        src: "/logos/twyford-logo.svg",
        width: { base: "69px", md: "117px" },
        height: { base: "32px", md: "40px" },
      }
    }
    return brandLogoProps
  }

  const { src, width, height } = getLogoProps()

  return (
    <Flex>
      <CustomImage src={src} width={width} height={height} alt={`${brands[brand]?.name} logo`} loading="eager" />
      {brand === brands.geberit.value && (
        <Text ml="2" fontSize="2xs" lineHeight="1" color="gray.500">
          {extractCountryCode(locale as string)}
        </Text>
      )}
    </Flex>
  )
}
