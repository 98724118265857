import { Box, Button, CloseButton, Container, Flex, UseDisclosureProps } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { Category } from "../../../../api-clients/product-api-v1"
import { layout, systemMenu } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import useIsomorphicLayoutEffect from "../../../../common/hooks/useIsomorphicLayoutEffect"
import useScrollBlock from "../../../../common/hooks/useScrollBlock"
import { scrollToTop } from "../../../../common/utils/common-utils"
import CategoryTabsSection from "./CategoryTabsSection"

type Props = UseDisclosureProps & {
  systemMenuData: Category
}

const SystemMenuContainer = ({ isOpen, onOpen, onClose, systemMenuData }: Props) => {
  const { t } = useBrandedTranslation()
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)

  /* Custom hook to control body scroll while menu is open */
  const [blockScroll, allowScroll] = useScrollBlock()

  /* Keep the selected category path in a ref for back path navigation reference */
  const categoryPathRef = useRef<Array<string>>([])

  const systemMenuContainerRef = useRef(null)

  /* Effect to trigger the system menu content animation in CategoryTabsSection component with a delay */
  useIsomorphicLayoutEffect(() => {
    let animationTimeout = null

    if (systemMenuContainerRef.current) {
      if (isOpen) {
        animationTimeout = setTimeout(() => (systemMenuContainerRef.current.style.display = "block"), 300)
      }
      if (!isOpen) systemMenuContainerRef.current.style.display = "none"
    }

    return () => {
      if (animationTimeout) clearTimeout(animationTimeout)
    }
  }, [isOpen])

  const handleBackNavigation = () => {
    /* Remove the current category from the category path */
    categoryPathRef.current.pop()

    /* Category path array is already mutated by pop(), 
    get the new last element which is the category we are returning to */
    const prevCategoryId = categoryPathRef.current.at(-1)
    setSelectedCategoryId(prevCategoryId)
  }

  const handleForwardNavigation = (nextCategoryId: string) => {
    /* Store the next selected category for path reference */
    categoryPathRef.current.push(nextCategoryId)
    setSelectedCategoryId(nextCategoryId)
  }

  const handleTabChange = (masterChapterCategoryId: string) => {
    /* Init the category path with the newly selected master chapter */
    categoryPathRef.current = [masterChapterCategoryId]
    setSelectedCategoryId(masterChapterCategoryId)
  }

  const handleOpen = () => {
    /* Block body scroll while the system menu is open */
    scrollToTop()
    blockScroll()

    /* Reset the menu state */
    categoryPathRef.current = []
    setSelectedCategoryId(null)

    onOpen()
  }

  const handleClose = () => {
    onClose()

    /* Allow body scroll again.
      Wait for the menu closing animation to finish. */
    setTimeout(() => allowScroll(), 300)

    /* Reset the menu state */
    categoryPathRef.current = []
    setSelectedCategoryId(null)
  }

  return (
    <>
      {/* Menu button, visible in the header */}
      <Button onClick={handleOpen} variant="navigationBar">
        {t("nav-assortment")}
      </Button>

      {/* Menu background with gray first part. Make it rendered by default so it can be animated on menu open. */}
      <Flex
        position="fixed"
        left={0}
        top={layout.DESKTOP_HEADER_HEIGHT}
        height={isOpen ? `calc(100% - ${layout.DESKTOP_HEADER_HEIGHT})` : "0"}
        width="100%"
        flexDirection="column"
        zIndex="popover"
        /* gray background width is calculated as 
            viewport width - main container width split by 2 
              (or 0 if viewport width is less than container width) 
               + left padding + gray tabs div width */
        background={`linear-gradient(90deg, var(--chakra-colors-gray-50) 
                    calc( max(0vw, calc(100vw - ${layout.DESKTOP_CONTAINER_WIDTH}) / 2) + ${layout.DESKTOP_CONTAINER_PADDING} + ${systemMenu.DESKTOP_TABS_WIDTH}), 
                    rgb(255, 255, 255) 0px)`}
        willChange="height"
        transition="height 0.3s ease-out"
      >
        {isOpen && (
          <>
            {/* Layer over header to close the system menu when clicking outside of it */}
            <Box
              position="fixed"
              left={0}
              top={0}
              minH={layout.DESKTOP_HEADER_HEIGHT}
              width="100%"
              display={isOpen ? "block" : "none"}
              onClick={handleClose}
              zIndex="popover"
            ></Box>
            {/* Menu container */}
            <Container
              ref={systemMenuContainerRef}
              variant="main"
              position="relative"
              width="100%"
              height="100vh"
              display="none"
            >
              {/* Menu main content */}
              <CategoryTabsSection
                selectedCategoryId={selectedCategoryId}
                macCategories={systemMenuData?.subCategories}
                handleTabChange={handleTabChange}
                handleBackNavigation={handleBackNavigation}
                handleForwardNavigation={handleForwardNavigation}
                handleClose={handleClose}
              />
              {/* Menu close button */}
              <CloseButton
                w={{ base: "11", sm: "10" }}
                h={{ base: "11", sm: "10" }}
                top="4"
                right="8"
                position="absolute"
                onClick={handleClose}
              />
            </Container>
          </>
        )}
      </Flex>
    </>
  )
}

export default SystemMenuContainer
